import { Icon } from '@chakra-ui/react';
import Button from 'common/components/button';
import { FaBoxOpen, FaCircleExclamation } from 'react-icons/fa6';
import LoadingSpinner from 'common/components/loading-spinner';

const EmptyPage = ({ title, subtitle, buttonText, onClick, isLoading, isError, PageIcon = FaBoxOpen, FullButton }) => {
  return (
    <div className='w-full flex flex-col gap-6 py-20 items-center text-center rounded-lg border border-gray-200'>
      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <Icon
          className='text-gray-500'
          boxSize={10}
          as={FaCircleExclamation}
        />
      ) : (
        <Icon
          className='text-gray-500'
          boxSize={10}
          as={PageIcon}
        />
      )}

      <div className='flex flex-col gap-2'>
        <h2 className='text-2xl font-display tracking-tight'>
          {title}
        </h2>

        <p className='text-base text-gray-500 font-normal w-full'>
          {subtitle}
        </p>
      </div>

      {FullButton ? (FullButton) : buttonText && onClick ? (
        <Button
          title={buttonText}
          onClick={onClick}
          wFull={false}
        />
      ) : null}
    </div>
  );
};

export default EmptyPage;
