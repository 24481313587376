import { formatAsPercentage, safeNumberConversion } from 'common/utils/misc';
import { bedroomEnum } from 'common/utils/enums';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import { Tag } from '@chakra-ui/react';
import FormatAsCurrency from 'common/utils/formatAsCurrency';

export const CalculateRateSummary = ({ values, calculatedRates, finalRates }) => {
  // Get the unit counts for each bedroom type
  const studioUnits = safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.STUDIO]?.numberOfUnits);
  const oneBedUnits = safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.ONE_BED]?.numberOfUnits);
  const twoBedUnits = safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.TWO_BED]?.numberOfUnits);
  const threeBedUnits = safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.THREE_BED]?.numberOfUnits);
  const fourBedPlusUnits = safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.FOUR_BED_PLUS]?.numberOfUnits);
  const totalUnits = studioUnits + oneBedUnits + twoBedUnits + threeBedUnits + fourBedPlusUnits;

  // Get the calculated rates for each bedroom type
  let studioRate = 0;
  let oneBedRate = 0;
  let twoBedRate = 0;
  let threeBedRate = 0;
  let fourBedPlusRate = 0;
  if (calculatedRates) {
    studioRate = calculatedRates[bedroomEnum.STUDIO]?.finalRate || 0;
    oneBedRate = calculatedRates[bedroomEnum.ONE_BED]?.finalRate || 0;
    twoBedRate = calculatedRates[bedroomEnum.TWO_BED]?.finalRate || 0;
    threeBedRate = calculatedRates[bedroomEnum.THREE_BED]?.finalRate || 0;
    fourBedPlusRate = calculatedRates[bedroomEnum.FOUR_BED_PLUS]?.finalRate || 0;
  } else if (finalRates) {
    studioRate = finalRates?.['0']?.rate || 0;
    oneBedRate = finalRates?.['1']?.rate || 0;
    twoBedRate = finalRates?.['2']?.rate || 0;
    threeBedRate = finalRates?.['3']?.rate || 0;
    fourBedPlusRate = finalRates?.['4+']?.rate || 0;
  }

  // Calculate the weighted average rate
  const weightedAverageRate = (
    (studioUnits * studioRate) +
    (oneBedUnits * oneBedRate) +
    (twoBedUnits * twoBedRate) +
    (threeBedUnits * threeBedRate) +
    (fourBedPlusUnits * fourBedPlusRate)
  ) / totalUnits;

  // Calculate the average monthly rent
  const averageMonthlyRent = (
    (studioUnits * safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.STUDIO]?.averageRent)) +
    (oneBedUnits * safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.ONE_BED]?.averageRent)) +
    (twoBedUnits * safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.TWO_BED]?.averageRent)) +
    (threeBedUnits * safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.THREE_BED]?.averageRent)) +
    (fourBedPlusUnits * safeNumberConversion(values.rates?.underwriting?.rentalUnitBreakdown[bedroomEnum.FOUR_BED_PLUS]?.averageRent))
  ) / totalUnits;

  return ({
    studioUnits: studioUnits,
    oneBedUnits: oneBedUnits,
    twoBedUnits: twoBedUnits,
    threeBedUnits: threeBedUnits,
    fourBedPlusUnits: fourBedPlusUnits,
    totalUnits: totalUnits,
    studioRate: studioRate,
    oneBedRate: oneBedRate,
    twoBedRate: twoBedRate,
    threeBedRate: threeBedRate,
    fourBedPlusRate: fourBedPlusRate,
    weightedAverageRate: weightedAverageRate,
    averageMonthlyRent: averageMonthlyRent,
  });
};


export const RateSummary = ({values, calculatedRates, finalRates, sticky=true }) => {

  const {
    studioUnits,
    oneBedUnits,
    twoBedUnits,
    threeBedUnits,
    fourBedPlusUnits,
    totalUnits,
    studioRate,
    oneBedRate,
    twoBedRate,
    threeBedRate,
    fourBedPlusRate,
    weightedAverageRate,
    averageMonthlyRent,
  } = CalculateRateSummary({ values, calculatedRates, finalRates });

  return (
    <InfoCard
      title={
        <div className='flex gap-3 items-center'>
          Rate Summary
          {values.rates?.underwriting?.defaultLeaseBuyout > 0 ? (
            <Tag colorScheme='blue' size='sm'>
              Fixed Coverage
            </Tag>
          ) : (
            <Tag colorScheme='green' size='sm'>
              Variable Coverage
            </Tag>
          )}
        </div>
      }
      className={sticky ? 'mobile:static sticky top-40 z-10' : 'static'}
    >
      {totalUnits > 0 && (
        <InfoGrid className='bg-gray-50'>
          {studioUnits > 0 && (
            <InfoField title='Studio (0bdr) Rate'>
              <span className='font-medium'>
                {formatAsPercentage(studioRate)}%
              </span>
            </InfoField>
          )}
          {oneBedUnits > 0 && (
            <InfoField title='1 Bedroom Rate'>
              <span className='font-medium'>
                {formatAsPercentage(oneBedRate)}%
              </span>
            </InfoField>
          )}
          {twoBedUnits > 0 && (
            <InfoField title='2 Bedroom Rate'>
              <span className='font-medium'>
                {formatAsPercentage(twoBedRate)}%
              </span>
            </InfoField>
          )}
          {threeBedUnits > 0 && (
            <InfoField title='3 Bedroom Rate'>
              <span className='font-medium'>
                {formatAsPercentage(threeBedRate)}%
              </span>
            </InfoField>
          )}
          {fourBedPlusUnits > 0 && (
            <InfoField title='4+ Bedroom Rate'>
              <span className='font-medium'>
                {formatAsPercentage(fourBedPlusRate)}%
              </span>
            </InfoField>
          )}
        </InfoGrid>
      )}
      <InfoGrid className='bg-gray-50'>
        <InfoField title='Total Units'>
          <span className='font-medium'>
            {totalUnits} units
          </span>
        </InfoField>
        {averageMonthlyRent > 0 && (
          <InfoField title='Average Monthly Rent'>
            <span className='font-medium'>
              {FormatAsCurrency(averageMonthlyRent, 'USD')}
            </span>
          </InfoField>
        )}
        {totalUnits > 0 && (
          <InfoField title='Average Rate'>
            <span className='font-medium'>
              {formatAsPercentage(weightedAverageRate)}%
            </span>
          </InfoField>
        )}
        {totalUnits > 0 && (
          <InfoField title='Average Monthly Price Per Unit'>
            <span className='font-medium'>
              {FormatAsCurrency(weightedAverageRate * averageMonthlyRent, 'USD')}
            </span>
          </InfoField>
        )}
      </InfoGrid>
    </InfoCard>
  );
};