import { useState } from 'react';
import {
  useUpdateClaimMutation,
  useGetClaimQuery,
  useUploadRequestUrlMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { claimConfirmationOptions } from 'common/utils/selectOptions';
import { claimConfirmationTypeEnum } from 'common/utils/enums';
import ModalBgLayout from 'common/layouts/modal-bg';
import { Formik } from 'formik';
import { toDatePickerFormat } from 'common/utils/toDatePickerFormat';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { InfoField } from 'common/components/info-card';
import SelectInput from 'common/components/select-input';
import FormError from 'common/components/form-error';
import Input from 'common/components/input';
import { uploadFileToS3 } from 'utils/uploadS3';
import Button from 'common/components/button';
import FileUploadInput from 'common/components/file-upload-input';

const LogConfirmationModal = ({ claimId, onCancel, confirmationType }) => {
  const [updateClaim, { isLoading: updateClaimLoading }] =
    useUpdateClaimMutation();

  const {
    data: claimData,
    isError: isGetError,
    error: getError,
  } = useGetClaimQuery(claimId);

  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadRequestUrl] = useUploadRequestUrlMutation();

  return (
    <ModalBgLayout>
      <Formik
        initialValues={{
          confirmationType: confirmationType || '',
          timestamp: toDatePickerFormat(new Date()),
          fileUrl: '',
        }}
        validationSchema={Yup.object().shape({
          confirmationType: Yup.string().required('Required'),
          timestamp: Yup.string().required('Required'),
          fileUrl: Yup.string().required('Required'),
        })}
        onSubmit={(values) => {
          let submissionValues = {
            claimID: claimId,
            confirmation: {
              ...{
                current: {},
                vacated: {},
                relisted: {},
              },
              ...claimData?.confirmation, // Preserve existing confirmation data
            },
            status: claimData?.status,
            review: claimData?.review,
            memberPayment: claimData?.memberPayment,
          };

          // Update the specific confirmation type without overwriting others
          if (values.confirmationType === claimConfirmationTypeEnum.VACATED) {
            submissionValues.confirmation.vacated = {
              ...submissionValues.confirmation.vacated, // Preserve existing vacated info
              timestamp: values.timestamp,
              fileUrl: values.fileUrl,
            };
          } else if (
            values.confirmationType === claimConfirmationTypeEnum.CURRENT
          ) {
            submissionValues.confirmation.current = {
              ...submissionValues.confirmation.current, // Preserve existing current info
              timestamp: values.timestamp,
              fileUrl: values.fileUrl,
            };
          } else if (
            values.confirmationType === claimConfirmationTypeEnum.RELISTED
          ) {
            submissionValues.confirmation.relisted = {
              ...submissionValues.confirmation.relisted, // Preserve existing relisted info
              timestamp: values.timestamp,
              fileUrl: values.fileUrl,
            };
          } else {
            return toast.error('Invalid confirmation type');
          }

          console.log('submissionValues: ', submissionValues);
          console.log('values: ', values);

          updateClaim(submissionValues)
            .unwrap()
            .then(() => {
              toast.success('Claim updated successfully');
              onCancel();
            })
            .catch(() => {
              toast.error('Failed to update claim');
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <div className='flex flex-col w-full gap-6 min-w-[400px]'>
            {/* icon and texts*/}
            <h3 className='text-2xl font-display tracking-tight'>
              Log Claim Confirmation
            </h3>

            <InfoField title='Confirmation Type'>
              <SelectInput
                options={claimConfirmationOptions}
                placeholder='Select Confirmation Type'
                name='confirmationType'
                value={claimConfirmationOptions.find(
                  (option) => option.value === values.confirmationType
                )}
                onChange={(selectedOption) => {
                  setFieldValue('confirmationType', selectedOption.value);
                }}
                onBlur={handleBlur}
              />
              <FormError name='confirmationType' />
            </InfoField>

            <InfoField title='Timestamp'>
              <Input
                type='date'
                name='timestamp'
                value={values.timestamp}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormError name='timestamp' />
            </InfoField>

            <InfoField title='Documentation'>
              <FileUploadInput
                disableUpload={disableFileUpload}
                isUploadError={uploadStatus.isError}
                isUploadLoading={uploadStatus.isLoading}
                isUploadSuccess={uploadStatus.isSuccess}
                handlePDFClear={() => {
                  setFieldValue('fileUrl', '');
                }}
                handlePDFUpload={async (file) => {
                  let uploadUrlReq;
                  setUploadStatus({
                    isSuccess: false,
                    isLoading: true,
                    isError: false,
                  });

                  // Get the signing url for uploading to s3
                  try {
                    uploadUrlReq = await uploadRequestUrl({
                      fileName: file.name,
                      contentType: file.type,
                    }).unwrap();
                  } catch (e) {
                    setUploadStatus({
                      isSuccess: false,
                      isError: true,
                      isLoading: false,
                    });
                    toast.error(
                      e?.msg || e?.message || 'failed to upload documentation'
                    );
                    return;
                  }

                  if (
                    !uploadUrlReq?.data?.uploadUrl ||
                    !uploadUrlReq?.data?.viewUrl
                  ) {
                    setUploadStatus({
                      isSuccess: false,
                      isError: true,
                      isLoading: false,
                    });
                    toast.error('failed to upload documentation');
                  }

                  // Upload the file to s3

                  try {
                    await uploadFileToS3({
                      s3Url: uploadUrlReq?.data?.uploadUrl,
                      file,
                    });

                    // Have to set the uploaded file url on fileUrl
                    setUploadStatus({
                      isSuccess: true,
                      isError: false,
                      isLoading: false,
                    });
                    setDisableFileUpload(true);
                    setFieldValue('fileUrl', uploadUrlReq?.data?.viewUrl);
                    toast.info(
                      uploadUrlReq?.status ||
                        'documentation has been uploaded successfully'
                    );
                  } catch (e) {
                    setUploadStatus({
                      isSuccess: false,
                      isError: true,
                      isLoading: false,
                    });
                    toast.error(
                      e?.msg || e?.message || 'failed to upload documentation'
                    );
                    return;
                  }
                }}
              />
              <FormError name='fileUrl' />
            </InfoField>

            {/* buttons */}
            <div className='flex flex-col items-center w-full gap-4 mt-4'>
              <Button
                disabled={!isValid || !dirty}
                onClick={handleSubmit}
                className='px-6'
                title='Log Payment'
              />
              <Button
                onClick={onCancel}
                className='px-6'
                theme='secondary'
                title='Cancel'
              />
            </div>
          </div>
        )}
      </Formik>
    </ModalBgLayout>
  );
};

export default LogConfirmationModal;
