import { propertyRatesStatusEnum } from 'common/utils/enums';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import RateEditor from 'components/properties/rate-editor';
import {
  useGetPropertyQuery,
  useUpdatePropertyRatesMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import Toggle from 'common/components/toggle';
import EmptyPage from 'common/components/empty-page';
import Button from 'common/components/button';
import FloatingButtons from 'common/components/floating-action-button';
import { ButtonGroup } from '@chakra-ui/react';
const PropertyRatesEdit = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
  } = useGetPropertyQuery(propertyId);
  console.log(propertyData);
  const [updatePropertyRates, { isLoading: isUpdateLoading }] =
    useUpdatePropertyRatesMutation();
  if (isGetError) {
    toast.error(getError?.data?.msg || 'Failed to fetch property details');
    return (
      <DashboardWrapperLayout title={'Failed to Fetch Property Details'}>
        <EmptyPage
          isError
          title='Failed to fetch property details'
          subtitle={getError?.data?.msg || 'Failed to fetch property details'}
          buttonText={'Reload Page'}
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }
  if (!propertyData || isGetLoading) {
    return (
      <DashboardWrapperLayout title={'Loading Property Details'}>
        <EmptyPage
          isLoading
          title='Loading Property Details'
          subtitle='Please wait while we load the Property details.'
        />
      </DashboardWrapperLayout>
    );
  }

  return (
    <Formik
      onSubmit={(values) => {
        let valueCp = JSON.parse(JSON.stringify(values.rates));
        delete valueCp.date;
        updatePropertyRates({ id: propertyId, ...valueCp })
          .unwrap()
          .then(() => {
            navigate(`/dashboard/properties/${propertyId}`);
          })
          .catch((e) => {
            toast.error(e?.data?.msg || 'Update Failed');
          });
      }}
      initialValues={{
        rates: {
          status: propertyRatesStatusEnum.PENDING,
          partnerTermSheet: '',
          underwriting: {
            state: propertyData?.address?.state || '',
            defaultLeaseBuyout:
              propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths ||
              0,
            propertyCommissionRate: propertyData?.owner?.program?.revShareRate || 0,
            rentalUnitBreakdown: {
              0: { numberOfUnits: 0, averageRent: 0, rate: 0 },
              1: { numberOfUnits: 0, averageRent: 0, rate: 0 },
              2: { numberOfUnits: 0, averageRent: 0, rate: 0 },
              3: { numberOfUnits: 0, averageRent: 0, rate: 0 },
              '4+': { numberOfUnits: 0, averageRent: 0, rate: 0 },
            },
          },
          ...propertyData?.rates,
        },
      }}
      validationSchema={Yup.object().shape({
        rates: Yup.object().shape({
          partnerTermSheet: Yup.string().required('Partner term sheet must be uploaded to create rates'),
          underwriting: Yup.object().shape({
            state: Yup.string().required('State is required'),
            propertyCommissionRate: Yup.number(),
            defaultLeaseBuyout: Yup.number(),
            trailing12MonthHistorical: Yup.object().shape({
              monthsOnMarket: Yup.string(),
              earlyTerminationRate: Yup.string(),
            }),
          }),
        }),
      })}
    >
      {({ 
        handleSubmit,
        dirty,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout
              title={isGetLoading ? 'Loading Rate Details' : `Change Rates for ${propertyData?.name}`}
              buttons={
                <Toggle
                  selected={'edit'}
                  editText='Edit Rates'
                  editPath={`/dashboard/properties/${propertyData?.id}/rates/edit`}
                  viewText='View Rates'
                  viewPath={`/dashboard/properties/${propertyData?.id}/rates`}
                />
              }
            >
              <>
                {/* Action Buttons */}
                <FloatingButtons
                  button={
                    <ButtonGroup>
                      <Button
                        onClick={handleSubmit}
                        title='Save Changes'
                        wFull={false}
                        disabled={isUpdateLoading || !dirty}
                      />
                      <Button
                        onClick={() =>
                          navigate(`/dashboard/properties/${propertyData?.id}`)
                        }
                        title='Cancel'
                        wFull={false}
                        theme='secondary'
                      />
                    </ButtonGroup>
                  }
                />
                {/* Editable Fields */}
                <RateEditor />
              </>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
};
export default PropertyRatesEdit;
