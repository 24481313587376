import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import InvoicesTable from 'components/invoices/invoices-table';
import { useFindInvoiceQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import EmptyPage from 'common/components/empty-page';

const OwnerFindInvoice = () => {
  const { ownerId, billingYear, billingMonth } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 15;
  const sortVal = '-createdAt'; // You can customize the default sort

  const {
    data: invoicesQuery,
    isError: isGetError,
    error: getError,
    isLoading: findInvoicesLoading,
  } = useFindInvoiceQuery({
    ownerID: ownerId,
    billingYear: billingYear,
    billingMonth: billingMonth,
  });

  const { data: invoicesData, meta: invoicesMeta } = invoicesQuery || {
    data: [],
    meta: { count: 0 },
  };

  useEffect(() => {
    if (invoicesData && invoicesData.length > 0) {
      // Assuming you want to navigate to the *first* invoice found.
      navigate(`/dashboard/invoices/${invoicesData[0].id}`);
    }
  }, [invoicesData, navigate]); // React to changes in invoicesData

  if (isGetError) {
    return (
      <DashboardWrapperLayout title='Invoices'>
        <EmptyPage
          isError={isGetError}
          title='Error Loading Invoices'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }

  if (findInvoicesLoading) {
    return (
      <DashboardWrapperLayout title='Invoices'>
        <EmptyPage
          title='Loading Invoices'
          subtitle='Please wait while we load invoices for this owner.'
          isLoading={findInvoicesLoading}
        />
      </DashboardWrapperLayout>
    );
  }

  return (
    <DashboardWrapperLayout title='Invoices'>
      <EmptyPage title='No Invoices Found' subtitle='No invoices found' />
    </DashboardWrapperLayout>
  );
};

export default OwnerFindInvoice;
