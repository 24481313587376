import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
  useCreateInvoiceAdjustmentMutation,
  useUpdateInvoiceAdjustmentMutation,
  useDeleteInvoiceAdjustmentMutation,
  useCreateInvoicePaymentMutation,
  useUpdateInvoicePaymentMutation,
  useDeleteInvoicePaymentMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import Button from 'common/components/button';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import { Select } from '@chakra-ui/react';
import { invoiceStatusEnum } from 'common/utils/enums';
import {
  BodyRow,
  HeaderCell,
  HeaderRow,
  Table,
  TableBody,
  TableCell,
} from 'common/components/data-table';
import Input from 'common/components/input';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormError from 'common/components/form-error';

const InvoiceEdit = () => {
  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const {
    data: invoice,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetInvoiceQuery(invoiceId);

  const [updateInvoice, { isLoading: isUpdating }] = useUpdateInvoiceMutation();
  const [createAdjustment, { isLoading: isCreatingAdjustment }] =
    useCreateInvoiceAdjustmentMutation();
  const [updateAdjustment, { isLoading: isUpdatingAdjustment }] =
    useUpdateInvoiceAdjustmentMutation();
  const [deleteAdjustment, { isLoading: isDeletingAdjustment }] =
    useDeleteInvoiceAdjustmentMutation();
  const [createPayment, { isLoading: isCreatingPayment }] =
    useCreateInvoicePaymentMutation();
  const [updatePayment, { isLoading: isUpdatingPayment }] =
    useUpdateInvoicePaymentMutation();
  const [deletePayment, { isLoading: isDeletingPayment }] =
    useDeleteInvoicePaymentMutation();

  const [newStatus, setNewStatus] = useState(invoice?.status || '');
  const [newAdjustmentAmount, setNewAdjustmentAmount] = useState('');
  const [editingAdjustmentId, setEditingAdjustmentId] = useState(null);
  const [newPaymentAmount, setNewPaymentAmount] = useState('');
  const [editingPaymentId, setEditingPaymentId] = useState(null);

  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleUpdateStatus = async () => {
    await updateInvoice({ id: invoiceId, status: newStatus });
  };

  const handleCreateAdjustment = async () => {
    await createAdjustment({
      id: invoiceId,
      amount: Number(newAdjustmentAmount),
    });
    setNewAdjustmentAmount('');
    refetch();
  };

  const handleUpdateAdjustment = async (adjustmentId, amount) => {
    await updateAdjustment({
      id: invoiceId,
      adjustmentId: adjustmentId,
      amount: Number(amount),
    });
    refetch();
  };

  const handleDeleteAdjustment = async (adjustmentId) => {
    await deleteAdjustment({ id: invoiceId, adjustmentId: adjustmentId });
    refetch();
  };

  const handleCreatePayment = async () => {
    await createPayment({ id: invoiceId, amount: Number(newPaymentAmount) });
    setNewPaymentAmount('');
    refetch();
  };

  const handleUpdatePayment = async (paymentId, amount) => {
    await updatePayment({
      id: invoiceId,
      paymentId: paymentId,
      amount: Number(amount),
    });
    refetch();
  };

  const handleDeletePayment = async (paymentId) => {
    await deletePayment({ id: invoiceId, paymentId: paymentId });
    refetch();
  };

  const initialValues = {
    status: invoice?.status || '',
  };

  const validationSchema = Yup.object({
    status: Yup.string().required('Status is required'),
  });

  const onSubmit = async (values) => {
    await updateInvoice({ id: invoiceId, ...values });
  };

  if (isLoading) {
    return (
      <DashboardWrapperLayout title='Edit Invoice'>
        Loading...
      </DashboardWrapperLayout>
    );
  }

  if (isError) {
    return (
      <DashboardWrapperLayout title='Edit Invoice'>
        Error: {error.message}
      </DashboardWrapperLayout>
    );
  }

  return (
    <DashboardWrapperLayout title='Edit Invoice'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
        }) => (
          <Form>
            <InfoCard title='Invoice Information'>
              <InfoGrid>
                <InfoField title='Invoice ID'>{invoice.id}</InfoField>
                <InfoField title='Amount'>{invoice.total}</InfoField>

                <InfoField title='Status'>
                  <Select
                    name='status'
                    value={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Select Status'
                  >
                    {Object.values(invoiceStatusEnum).map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </Select>
                  <FormError name='status' />
                </InfoField>
              </InfoGrid>
              <Button
                onClick={handleSubmit}
                disabled={isUpdating}
                loading={isUpdating}
                title='Update Status'
                type='submit'
              />
            </InfoCard>
          </Form>
        )}
      </Formik>

      {/* Adjustments Section */}
      <InfoCard title='Adjustments'>
        <Table>
          <HeaderRow>
            <HeaderCell>Amount</HeaderCell>
            <HeaderCell>Actions</HeaderCell>
          </HeaderRow>
          <TableBody>
            {invoice?.adjustments?.map((adjustment) => (
              <BodyRow key={adjustment.id}>
                <TableCell>
                  {editingAdjustmentId === adjustment.id ? (
                    <Input
                      type='number'
                      value={newAdjustmentAmount}
                      onChange={(e) => setNewAdjustmentAmount(e.target.value)}
                      onBlur={(e) => {
                        setEditingAdjustmentId(null);
                      }}
                    />
                  ) : (
                    adjustment.amount
                  )}
                </TableCell>
                <TableCell>
                  {editingAdjustmentId === adjustment.id ? (
                    <>
                      <Button
                        onClick={() => {
                          handleUpdateAdjustment(
                            adjustment.id,
                            newAdjustmentAmount
                          );
                          setEditingAdjustmentId(null);
                        }}
                        theme='secondary'
                        title='Save'
                        wFull={false}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          setEditingAdjustmentId(adjustment.id);
                          setNewAdjustmentAmount(adjustment.amount);
                        }}
                        theme='secondary'
                        title='Edit'
                        wFull={false}
                      />
                      <Button
                        onClick={() => handleDeleteAdjustment(adjustment.id)}
                        theme='secondary'
                        title='Delete'
                        wFull={false}
                      />
                    </>
                  )}
                </TableCell>
              </BodyRow>
            ))}
          </TableBody>
        </Table>
      </InfoCard>
      <InfoCard title='New Adjustment'>
        <InfoGrid></InfoGrid>
        <InfoField title='Amount'>
          <Input
            type='number'
            value={newAdjustmentAmount}
            onChange={(e) => {
              setNewAdjustmentAmount(e.target.value);
            }}
            placeholderText='Enter Number'
          />
        </InfoField>
        <Button
          onClick={handleCreateAdjustment}
          title='Create Adjustment'
          wFull={false}
        />
      </InfoCard>

      {/* Payments Section */}
      <InfoCard title='Payments'>
        <Table>
          <HeaderRow>
            <HeaderCell>Amount</HeaderCell>
            <HeaderCell>Actions</HeaderCell>
          </HeaderRow>
          <TableBody>
            {invoice?.payments?.map((payment) => (
              <BodyRow key={payment.id}>
                <TableCell>
                  {editingPaymentId === payment.id ? (
                    <Input
                      type='number'
                      value={newPaymentAmount}
                      onChange={(e) => setNewPaymentAmount(e.target.value)}
                      onBlur={(e) => {
                        setEditingPaymentId(null);
                      }}
                    />
                  ) : (
                    payment.amount
                  )}
                </TableCell>
                <TableCell>
                  {editingPaymentId === payment.id ? (
                    <>
                      <Button
                        onClick={() => {
                          handleUpdatePayment(payment.id, newPaymentAmount);
                          setEditingPaymentId(null);
                        }}
                        theme='secondary'
                        title='Save'
                        wFull={false}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          setEditingPaymentId(payment.id);
                          setNewPaymentAmount(payment.amount);
                        }}
                        theme='secondary'
                        title='Edit'
                        wFull={false}
                      />
                      <Button
                        onClick={() => handleDeletePayment(payment.id)}
                        theme='secondary'
                        title='Delete'
                        wFull={false}
                      />
                    </>
                  )}
                </TableCell>
              </BodyRow>
            ))}
          </TableBody>
        </Table>
      </InfoCard>
      <InfoCard title='New Payment'>
        <InfoGrid></InfoGrid>
        <InfoField title='Amount'>
          <Input
            type='number'
            value={newPaymentAmount}
            onChange={(e) => {
              setNewPaymentAmount(e.target.value);
            }}
            placeholderText='Enter Number'
          />
        </InfoField>
        <Button
          onClick={handleCreatePayment}
          title='Create Payment'
          wFull={false}
        />
      </InfoCard>
    </DashboardWrapperLayout>
  );
};

export default InvoiceEdit;
