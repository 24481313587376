import Button from "common/components/button";
import FormError from "common/components/form-error";
import { InfoField } from "common/components/info-card";
import SelectInput from "common/components/select-input";
import ModalBgLayout from "common/layouts/modal-bg";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useUpdatePlanMutation, useGetPlanQuery } from "redux/api/ciosAdminApi/ciosAdminApi";
import { allPlanStatusOptions } from "common/utils/selectOptions";
import { useEffect } from "react";

const ChangeStatusModal = ({planId, onCancel}) => {
  const [updatePlan, {isLoading}] = useUpdatePlanMutation();

  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
  } = useGetPlanQuery(planId, {skip: !planId});
  const {data: planData} = planQuery || {
    data: {},
  };

  useEffect(() => {
    if (planData) {
      console.log(planData);
    }
  }, [planData]);

  return (
    <ModalBgLayout>
      <Formik
        initialValues={{
          status: planData?.status,
        }}
        onSubmit={(values) => {
          let valueCp = JSON.parse(JSON.stringify(values));
          
          updatePlan({
            id: planId,
            status: valueCp.status,            
          })
            .unwrap()
            .then(() => {
              toast.success('Plan status updated successfully');
              onCancel();
            })
            .catch((err) => {
              toast.error('Failed to update plan status');
            });
        }}
      >
        {({ 
          handleSubmit, 
          handleChange,
          handleBlur,
          setFieldValue, 
          values,
          isValid,
          dirty,
        }) => (
          <div className='flex flex-col w-full gap-6 min-w-[400px]'>
            <h3 className='text-2xl font-display tracking-light'>
              Change Plan Status
            </h3>

            <InfoField title='Plan Status' className='col-span-2'>
              <SelectInput
                options={allPlanStatusOptions}
                placeholder='Select Status'
                name='status'
                value={allPlanStatusOptions.find(
                  (status) => status.value === values.status
                )}
                onChange={(selectedOption) => {
                  setFieldValue('status', selectedOption?.value || '');
                }}
                onBlur={handleBlur}
              />
              <FormError name='status' />
            </InfoField>
            
            <div className='flex flex-col items-center w-full gap-4 mt-4'>
              <Button
                disabled={!isValid || !dirty || isLoading}
                onClick={handleSubmit}
                title='Update Plan Status'
              />
              <Button
                theme='secondary'
                onClick={onCancel}
                title='Cancel'
              />
            </div>
          </div>
        )}
      </Formik>
    </ModalBgLayout>
  )
}

export default ChangeStatusModal;