import { useState } from 'react';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import {
  BodyRow,
  HeaderCell,
  HeaderRow,
  Table,
  TableBody,
  TableCell,
} from 'common/components/data-table';
import SortButton from 'common/components/sort-button';
import { formatPhoneNumber } from 'react-phone-number-input';
import { StatusLabel } from 'components/invoices/status-label';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { HamburgerIcon, ViewIcon } from '@chakra-ui/icons';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa6';

const InvoicesTable = ({ invoices, sortVal, setSort, isRounded = true }) => {
  console.log(invoices);
  // DATA INITIALIZATION
  const navigate = useNavigate();

  const gridLayout = 'grid-cols-5 mobile:grid-cols-2';

  return (
    <Table>
      {/* head */}
      <HeaderRow isRounded={isRounded} gridLayout={gridLayout}>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='name'
            label='Invoice Name'
          />
        </HeaderCell>
        <HeaderCell className='mobile:hidden'>Contact Info</HeaderCell>
        <HeaderCell className='mobile:hidden'>Address</HeaderCell>
        <HeaderCell className='mobile:hidden'>Default Rate</HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='status'
            label='Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {invoices?.map((invoice, index) => (
          <BodyRow
            gridLayout={gridLayout}
            key={index}
            onClick={() => {
              navigate(`/dashboard/invoices/${invoice.id}`);
            }}
          >
            {/* Name and ID */}
            <TableCell top={invoice?.name} bottom={invoice?.id} />

            {/* Contact Info (Email + Phone) */}
            <TableCell
              top={invoice?.email}
              topLink={`mailto:${invoice?.email}`}
              bottom={formatPhoneNumber(invoice?.phone)}
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
              className='mobile:hidden'
            />

            {/* Address */}
            {/* Placeholder when address is undefined (shouldn't happen) */}
            {invoice?.address?.streetAddress1 ? (
              <TableCell
                top={`${invoice?.address.streetAddress1}${
                  invoice?.address?.streetAddress2 ? ', ' : ''
                }${invoice?.address?.streetAddress2 || ''}`}
                bottom={`${invoice?.address.city}, ${
                  invoice?.address.state || ''
                } ${invoice?.address.postalCode || ''}`}
                className='mobile:hidden'
              />
            ) : (
              <TableCell top='-' bottom='-' className='mobile:hidden' />
            )}

            {/* Default Rate */}
            {/* TO-DO NOTE NEED TO FIX */}
            <td className='flex gap-2 mobile:hidden'>
              <TableCell
                top={`${
                  invoice?.cancelAnytimeRate
                    ? (invoice?.cancelAnytimeRate * 100).toFixed(2)
                    : 3.89
                }%`}
                bottom='Cancel Anytime Rate'
              />

              <TableCell
                top={`${
                  invoice?.leaveAnytimeRate
                    ? (invoice?.leaveAnytimeRate * 100).toFixed(2)
                    : 3.89
                }%`}
                bottom='Leave Anytime Rate'
              />
            </td>

            {/* Status */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel status={invoice?.status} size='sm' />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='ghost'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/invoices/${invoice?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View invoice Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/invoices/${invoice?.id}/edit`);
                    }}
                    icon={<BiEdit />}
                  >
                    Edit Invoice Details
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InvoicesTable;
