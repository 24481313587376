import Button from 'common/components/button';
import LoadingSpinner from 'common/components/loading-spinner';

const SetupCard = ({
  title,
  subtitle,
  message,
  buttonTitle,
  onClick,
  className,
  isLoading=false,
}) => {
  return (
    <div className={`flex flex-col gap-3 ${className}`}>
      {isLoading ? (
        <div className='mb-3'>
          <LoadingSpinner 
            width='30'
            fullWidth={false}
          />
        </div>
      ) : null}
      <h2 className='text-lg font-medium'>{title}</h2>
      <p className='text-base'>{subtitle}</p>
      <p className='text-base text-gray-500'>{message}</p>

      {/* Show button if defined */}
      <div>
        {buttonTitle && onClick ? (
          <Button onClick={onClick} title={buttonTitle} wFull={false} className='mt-4' />
        ) : null}
      </div>
    </div>
  );
};

export { SetupCard };
