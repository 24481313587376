import React, { useState, useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import Input from 'common/components/input';
import SelectInput from 'common/components/select-input';
import FormError from 'common/components/form-error';
import {
  stateOptions,
  propertyRatesStatusOptions,
} from 'common/utils/selectOptions';
import { InfoGrid, InfoField, InfoCard } from 'common/components/info-card';
import { bedroomEnum } from 'common/utils/enums';
import {
  useGetAdminPropertyRatesQuery,
  useUploadRequestUrlMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import FileUploadInput from 'common/components/file-upload-input';
import { uploadFileToS3 } from 'utils/uploadS3';
import { toast } from 'react-toastify';
import { isEmpty } from 'utils/isEmpty';
import { safeNumberConversion, useDeepCompareEffect } from 'common/utils/misc';
import { RateSummary } from 'components/properties/rate-summary';
import EmptyPage from 'common/components/empty-page';
import PercentDecimalInput from 'common/components/percent-decimal-input';

// Helper function to determine rent quintile
const getRentQuintile = (rent) => {
  if (rent < 650) return 'q1';
  if (rent <= 900) return 'q2';
  if (rent <= 1250) return 'q3';
  if (rent <= 2000) return 'q4';
  return 'q5';
};

// Helper function to map bedroom type to the key used in the rate data
const mapBedroomToKey = (bedroomType) => {
  switch (bedroomType) {
    case bedroomEnum.STUDIO:
      return '0';
    case bedroomEnum.ONE_BED:
      return '1';
    case bedroomEnum.TWO_BED:
      return '2';
    case bedroomEnum.THREE_BED:
      return '3';
    case bedroomEnum.FOUR_BED_PLUS:
      return '4+';
    default:
      return '0';
  }
};

// Function to get human-readable bedroom label
const getBedroomLabel = (bedroomType) => {
  switch (bedroomType) {
    case bedroomEnum.STUDIO:
      return 'Studio (0 bdr)';
    case bedroomEnum.ONE_BED:
      return '1 Bedroom';
    case bedroomEnum.TWO_BED:
      return '2 Bedrooms';
    case bedroomEnum.THREE_BED:
      return '3 Bedrooms';
    case bedroomEnum.FOUR_BED_PLUS:
      return '4+ Bedrooms';
    default:
      return 'Unknown';
  }
};

const RateEditor = () => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext();
  const [calculatedRates, setCalculatedRates] = useState({});
  const [buildingAdjustments, setBuildingAdjustments] = useState({
    buildingSevAdjustment: 1,
    buildingFreqAdjustment: 1,
  });

  // Set up states for file upload
  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadRequestUrl] = useUploadRequestUrlMutation();

  const {
    data: propertyRatesData,
    isError: isGetPropertyRatesError,
    error: getPropertyRatesError,
    isLoading: isGetPropertyRatesLoading,
  } = useGetAdminPropertyRatesQuery();

  // Base rates and constants
  const BASE_RATE = 0.0196; // 1.96%
  const BASE_SEVERITY = 2.35; // Base severity in months
  const BASE_FREQUENCY = 0.0294; // Base early termination rate (2.94%)
  const BASE_SEVERITY_PREMIUM = 1.25; // Base severity premium
  const BASE_FREQUENCY_PREMIUM = 1.25; // Base frequency premium

  // Ensure the rentalUnitBreakdown structure is complete with all bedroom types
  useEffect(() => {
    if (!values.rates) {
      setFieldValue('rates', {
        partnerTermSheet: '',
        underwriting: {
          state: '',
          defaultLeaseBuyout: 0,
          propertyCommissionRate: 0, // Default to 0% (0)
          trailing12MonthHistorical: {
            monthsOnMarket: BASE_SEVERITY * BASE_SEVERITY_PREMIUM,
            earlyTerminationRate: BASE_FREQUENCY * BASE_FREQUENCY_PREMIUM
          },
          rentalUnitBreakdown: {},
        },
      });
    } else if (!values.rates.underwriting) {
      setFieldValue('rates.underwriting', {
        state: '',
        defaultLeaseBuyout: 0,
        propertyCommissionRate: 0, // Default to 0% (0)
        trailing12MonthHistorical: {
          monthsOnMarket: BASE_SEVERITY * 1.25, // Default to BASE_SEVERITY (2.35) * 1.25,
          earlyTerminationRate: BASE_FREQUENCY * 1.25, // Default to BASE_FREQUENCY (2.94%) * 1.25
        },
        rentalUnitBreakdown: {},
      });
    } else if (!values.rates.underwriting.rentalUnitBreakdown) {
      setFieldValue('rates.underwriting.rentalUnitBreakdown', {});
    }

    if (!values.rates.underwriting?.trailing12MonthHistorical) {
      setFieldValue('rates.underwriting.trailing12MonthHistorical', {
        monthsOnMarket: BASE_SEVERITY * 1.25,
        earlyTerminationRate: BASE_FREQUENCY * 1.25,
      });
    }

    // Ensure all bedroom types exist with default values
    const currentBreakdown =
      values.rates?.underwriting?.rentalUnitBreakdown || {};

    Object.values(bedroomEnum).forEach((bedroomType) => {
      if (!currentBreakdown[bedroomType]) {
        setFieldValue(`rates.underwriting.rentalUnitBreakdown.${bedroomType}`, {
          numberOfUnits: '',
          averageRent: '',
          rate: '',
        });
      }
    });
  }, []);

  // Calculate building-level adjustments
  useEffect(() => {
    const ttmMonthsOnMarket = safeNumberConversion(
      values.rates?.underwriting?.trailing12MonthHistorical?.monthsOnMarket
    );
    const ttmEarlyTermRate = safeNumberConversion(
      values.rates?.underwriting?.trailing12MonthHistorical
        ?.earlyTerminationRate
    );
    // Calculate building-level adjustments
    const buildingSevAdjustment =
      ttmMonthsOnMarket > 0
        ? 1 - (BASE_SEVERITY - ttmMonthsOnMarket) / BASE_SEVERITY
        : 1;
    const buildingFreqAdjustment =
      ttmEarlyTermRate > 0
        ? 1 - (BASE_FREQUENCY - ttmEarlyTermRate) / BASE_FREQUENCY
        : 1;

    // Save adjustments for display
    setBuildingAdjustments({
      buildingSevAdjustment,
      buildingFreqAdjustment,
    });
  }, [
    values.rates?.underwriting?.trailing12MonthHistorical?.monthsOnMarket,
    values.rates?.underwriting?.trailing12MonthHistorical?.earlyTerminationRate,
  ]);

  // Calculate rates for all bedroom types
  const calculateRates = () => {
    // Check if we have the required data from API
    if (
      !propertyRatesData ||
      !propertyRatesData.frequency ||
      !propertyRatesData.severity
    ) {
      console.warn('Property rates data not available yet');
      return;
    }

    const frequencyData = propertyRatesData.frequency;
    const severityData = propertyRatesData.severity;

    console.log('propertyRatesData', propertyRatesData);

    const state = values.rates?.underwriting?.state;
    if (!state) {
      console.warn('State not selected');
      return;
    }

    const propertyCommissionRate = safeNumberConversion(
      values.rates?.underwriting?.propertyCommissionRate
    );

    const defaultLeaseBuyout = safeNumberConversion(
      values.rates?.underwriting?.defaultLeaseBuyout
    );

    const rentalUnitBreakdown =
      values.rates?.underwriting?.rentalUnitBreakdown || {};

    const totalUnits = Object.values(rentalUnitBreakdown).reduce(
      (sum, unit) => sum + safeNumberConversion(unit.numberOfUnits),
      0
    );

    // Volume discount calculation
    let volumeDiscount = 0;
    if (totalUnits >= 250) volumeDiscount = 0.1; // 10%
    else if (totalUnits >= 150) volumeDiscount = 0.07; // 7%
    else if (totalUnits >= 100) volumeDiscount = 0.04; // 4%
    else if (totalUnits >= 75) volumeDiscount = 0.02; // 2%
    else volumeDiscount = 0; // 0%

    // Total discount calculation
    // Total discount = volume discount + (10% - property commission rate)
    const totalDiscount = volumeDiscount + (0.1 - propertyCommissionRate);

    // For each unit type, calculate the rate
    const newRates = {};

    Object.values(bedroomEnum).forEach((bedroomType) => {
      const unitData = rentalUnitBreakdown[bedroomType] || {
        numberOfUnits: '',
        averageRent: '',
        rate: '',
      };
      const rent = safeNumberConversion(unitData.averageRent);
      const quintile = getRentQuintile(rent);
      const dataKey = mapBedroomToKey(bedroomType);

      // Get rate factors from tables
      let freqPremium = BASE_FREQUENCY_PREMIUM;
      let sevPremium = BASE_SEVERITY_PREMIUM;

      // Look up frequency and severity premiums from the imported data
      if (
        frequencyData[state] &&
        frequencyData[state][quintile] &&
        frequencyData[state][quintile][dataKey] != null && 
        frequencyData[state][quintile][dataKey] !== undefined
      ) {
        freqPremium = frequencyData[state][quintile][dataKey];
      } else {
        console.warn(
          `Frequency data not found for ${state}/${quintile}/${dataKey}, using default`
        );
      }

      if (
        severityData[state] &&
        severityData[state][quintile] &&
        severityData[state][quintile][dataKey] != null &&
        severityData[state][quintile][dataKey] !== undefined
      ) {
        sevPremium = severityData[state][quintile][dataKey];
      } else {
        console.warn(
          `Severity data not found for ${state}/${quintile}/${dataKey}, using default`
        );
      }

      // Calculate final rate
      let expectedSeverity;
      let adjustedRate;
      let fixedSeverityPremium;

      if (defaultLeaseBuyout > 0) {
        // Fixed severity calculation
        expectedSeverity = defaultLeaseBuyout;
        fixedSeverityPremium =
          1 - (BASE_SEVERITY - defaultLeaseBuyout) / BASE_SEVERITY;
        adjustedRate =
          BASE_RATE *
          freqPremium *
          buildingAdjustments.buildingFreqAdjustment *
          fixedSeverityPremium;
      } else {
        // Variable severity calculation
        expectedSeverity =
          BASE_SEVERITY *
          sevPremium *
          buildingAdjustments.buildingSevAdjustment;
        adjustedRate =
          BASE_RATE *
          freqPremium *
          sevPremium *
          buildingAdjustments.buildingFreqAdjustment *
          buildingAdjustments.buildingSevAdjustment;
      }

      // Apply discount
      const finalRate = adjustedRate * (1 - totalDiscount);

      newRates[bedroomType] = {
        freqPremium,
        sevPremium,
        expectedSeverity,
        adjustedRate,
        finalRate,
        totalDiscount,
      };

      // Update the rates in the form
      setFieldValue(
        `rates.underwriting.rentalUnitBreakdown.${bedroomType}.rate`,
        newRates[bedroomType].finalRate
      );
    });

    setCalculatedRates(newRates);
  };

  

  // Calculate when key values change and data is ready
  useDeepCompareEffect(() => {
    if (
      propertyRatesData &&
      propertyRatesData.frequency &&
      propertyRatesData.severity &&
      values.rates?.underwriting?.state &&
      values.rates?.underwriting?.trailing12MonthHistorical?.monthsOnMarket &&
      values.rates?.underwriting?.trailing12MonthHistorical
        ?.earlyTerminationRate
    ) {
      calculateRates();
    }
  }, [
    propertyRatesData,
    values.rates?.underwriting?.state,
    values.rates?.underwriting?.trailing12MonthHistorical?.monthsOnMarket,
    values.rates?.underwriting?.trailing12MonthHistorical?.earlyTerminationRate,
    values.rates?.underwriting?.defaultLeaseBuyout,
    values.rates?.underwriting?.propertyCommissionRate,
    JSON.stringify(values.rates?.underwriting?.rentalUnitBreakdown),
  ]);

  // Render the card for each unit layout type
  const renderRentalUnitBreakdownFields = () => {
    return Object.values(bedroomEnum).map((bedroomType) => {
      const breakdown = values.rates?.underwriting?.rentalUnitBreakdown || {};

      return (
        <InfoCard key={bedroomType} title={getBedroomLabel(bedroomType)}>
          <InfoGrid>
            <InfoField title='Number of Units'>
              <Input
                type='number'
                min='0'
                placeholderText='Number of Units'
                name={`rates.underwriting.rentalUnitBreakdown.${bedroomType}.numberOfUnits`}
                value={breakdown[bedroomType]?.numberOfUnits}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormError
                name={`rates.underwriting.rentalUnitBreakdown.${bedroomType}.numberOfUnits`}
              />
            </InfoField>

            <InfoField title='Average Monthly Rent'>
              <Input
                type='number'
                min='0'
                placeholderText='Average Rent'
                name={`rates.underwriting.rentalUnitBreakdown.${bedroomType}.averageRent`}
                value={breakdown[bedroomType]?.averageRent}
                onChange={handleChange}
                onBlur={handleBlur}
                prefix='$'
              />
              <FormError
                name={`rates.underwriting.rentalUnitBreakdown.${bedroomType}.averageRent`}
              />
            </InfoField>

            <InfoField title='Calculated Rate'>
              <Field name={`rates.underwriting.rentalUnitBreakdown.${bedroomType}.rate`}>
                {({ field, form }) => (
                  <PercentDecimalInput
                    {...field}
                    placeholderText='Enter percentage'
                    disabled
                  />
                )}
              </Field>
                
              <FormError
                name={`rates.underwriting.rentalUnitBreakdown.${bedroomType}.rate`}
              />
            </InfoField>
          </InfoGrid>

          {calculatedRates[bedroomType] &&
          breakdown[bedroomType]?.numberOfUnits > 0 ? (
            <InfoGrid className='bg-gray-50'>
              <InfoField title='Rent Quintile'>
                <span className='font-medium capitalize'>
                  {getRentQuintile(
                    safeNumberConversion(breakdown[bedroomType]?.averageRent)
                  )}
                </span>
              </InfoField>
              <InfoField title='Frequency Premium'>
                <span className='font-medium'>
                  {calculatedRates[bedroomType].freqPremium.toFixed(2)}
                </span>
              </InfoField>
              <InfoField title='Severity Premium'>
                <span className='font-medium'>
                  {calculatedRates[bedroomType].sevPremium.toFixed(2)}
                </span>
              </InfoField>
              <InfoField title='Expected Severity'>
                <span className='font-medium'>
                  {calculatedRates[bedroomType].expectedSeverity.toFixed(2)}{' '}
                  months
                </span>
              </InfoField>
            </InfoGrid>
          ) : null}
        </InfoCard>
      );
    });
  };

  if (isGetPropertyRatesLoading) {
    return (
      <EmptyPage
        isLoading
        title='Loading Rate Details'
        subtitle='Please wait while we load the rate details.'
      />
    );
  }

  if (isGetPropertyRatesError) {
    return (
      <EmptyPage
        isError
        title='Failed to fetch rate details'
        subtitle={
          getPropertyRatesError?.data?.msg || 'Failed to fetch rate details'
        }
        buttonText={'Reload Page'}
        onClick={() => window.location.reload()}
      />
    );
  }

  return (
    <div className='flex flex-col w-full gap-8'>
      {/* Rating Summary */}
      <RateSummary values={values} calculatedRates={calculatedRates} />

      {/* Entered Fields */}
      <InfoCard title='Property Information'>
        <InfoGrid>
          <InfoField title='Status'>
            <SelectInput
              options={propertyRatesStatusOptions}
              placeholder='Select a Status'
              name='rates.status'
              value={propertyRatesStatusOptions.find(
                (option) => option.value === values.rates?.status
              )}
              onChange={(selectedOption) => {
                setFieldValue('rates.status', selectedOption?.value || '');
              }}
              onBlur={handleBlur}
            />
            <FormError name='rates.underwriting.state' />
          </InfoField>
          <InfoField title='Partner Term Sheet' className='col-span-2'>
            <FileUploadInput
              existingFileUrl={values?.rates?.partnerTermSheet}
              disableUpload={disableFileUpload}
              isUploadError={uploadStatus.isError}
              isUploadLoading={uploadStatus.isLoading}
              isUploadSuccess={
                uploadStatus.isSuccess ||
                !isEmpty(values.rates.partnerTermSheet)
              }
              handlePDFClear={() => {
                setFieldValue('rates.partnerTermSheet', '');
              }}
              handlePDFUpload={async (file) => {
                let uploadUrlReq;
                setUploadStatus({
                  isSuccess: false,
                  isLoading: true,
                  isError: false,
                });

                // Get the signing url for uploading to s3
                try {
                  uploadUrlReq = await uploadRequestUrl({
                    fileName: file.name,
                    contentType: file.type,
                  }).unwrap();
                } catch (e) {
                  setUploadStatus({
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                  });
                  toast.error(
                    e?.msg || e?.message || 'failed to upload document'
                  );
                  return;
                }

                if (
                  !uploadUrlReq?.data?.uploadUrl ||
                  !uploadUrlReq?.data?.viewUrl
                ) {
                  setUploadStatus({
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                  });
                  toast.error('failed to upload document');
                }

                // Upload the file to s3

                try {
                  await uploadFileToS3({
                    s3Url: uploadUrlReq?.data?.uploadUrl,
                    file,
                  });

                  // Have to set the uploaded file url on the form field
                  setUploadStatus({
                    isSuccess: true,
                    isError: false,
                    isLoading: false,
                  });
                  setDisableFileUpload(true);
                  setFieldValue(
                    'rates.partnerTermSheet',
                    uploadUrlReq?.data?.viewUrl
                  );
                  toast.info(
                    uploadUrlReq?.status ||
                      'file has been uploaded successfully'
                  );
                } catch (e) {
                  setUploadStatus({
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                  });
                  toast.error(
                    e?.msg || e?.message || 'failed to upload document'
                  );
                  return;
                }
              }}
            />
            <FormError name='rates.partnerTermSheet' />
          </InfoField>
        </InfoGrid>
        <InfoGrid>
          <InfoField title='State'>
            <SelectInput
              options={stateOptions}
              placeholder='Select a State'
              name='rates.underwriting.state'
              value={stateOptions.find(
                (option) => option.value === values.rates?.underwriting?.state
              )}
              onChange={(selectedOption) => {
                setFieldValue(
                  'rates.underwriting.state',
                  selectedOption?.value || ''
                );
              }}
              onBlur={handleBlur}
            />
            <FormError name='rates.underwriting.state' />
          </InfoField>
          <InfoField title='Fixed Buyout Months'>
            <Input
              type='number'
              min='0'
              placeholderText='Buyout in Months'
              name='rates.underwriting.defaultLeaseBuyout'
              value={values.rates?.underwriting?.defaultLeaseBuyout}
              onChange={handleChange}
              onBlur={handleBlur}
              suffix='months'
            />
            <div className='text-xs text-gray-500 mt-1'>
              Set to 0 for variable coverage
            </div>
            <FormError name='rates.underwriting.defaultLeaseBuyout' />
          </InfoField>
          <InfoField title='Property Commission Rate'>
            <Field name='rates.underwriting.propertyCommissionRate'>
              {({ field, form }) => (
                <PercentDecimalInput
                  {...field}
                  placeholderText='Enter percentage'
                />
              )}
            </Field>
            <FormError name='rates.underwriting.propertyCommissionRate' />
          </InfoField>
        </InfoGrid>
      </InfoCard>
      {/* Trailing 12 Month Historical Data */}
      <InfoCard title='Trailing 12 Month Historical Data'>
        <InfoGrid>
          <InfoField title='Months on Market'>
            <Input
              type='number'
              min='0'
              step='0.01'
              placeholderText='Months on Market'
              name='rates.underwriting.trailing12MonthHistorical.monthsOnMarket'
              value={
                values.rates?.underwriting?.trailing12MonthHistorical
                  ?.monthsOnMarket
              }
              onChange={handleChange}
              onBlur={handleBlur}
              suffix='months'
            />
            <FormError name='rates.underwriting.trailing12MonthHistorical.monthsOnMarket' />
          </InfoField>

          <InfoField title='Early Termination Rate'>
            <Field name='rates.underwriting.trailing12MonthHistorical.earlyTerminationRate'>
              {({ field, form }) => (
                <PercentDecimalInput
                  {...field}
                  placeholderText='Enter percentage'
                />
              )}
            </Field>          
            <FormError name='rates.underwriting.trailing12MonthHistorical.earlyTerminationRate' />
          </InfoField>
        </InfoGrid>

        {/* Building adjustments info */}
        {values.rates?.underwriting?.trailing12MonthHistorical
          ?.monthsOnMarket &&
          values.rates?.underwriting?.trailing12MonthHistorical
            ?.earlyTerminationRate && (
            <InfoGrid className='bg-gray-50'>
              <InfoField title='Building Severity Adjustment'>
                <span className='font-medium'>
                  {buildingAdjustments.buildingSevAdjustment.toFixed(2)}
                </span>
              </InfoField>
              <InfoField title='Building Frequency Adjustment'>
                <span className='font-medium'>
                  {buildingAdjustments.buildingFreqAdjustment.toFixed(2)}
                </span>
              </InfoField>
            </InfoGrid>
          )}
      </InfoCard>
      {/* Fixed bedroom fields */}
      {renderRentalUnitBreakdownFields()}
    </div>
  );
};

export default RateEditor;
