import { SetupCard } from 'common/components/setup-card';
import ModalBgLayout from 'common/layouts/modal-bg';
import React from 'react';
import BgLayout from 'common/components/bg';

const LoadingPageLayout = ({ 
  background,
  title='Logging You Into Your ReLease Account',
  subtitle='Please wait while we log you in',
  message="We're logging you into your account. This may take a few seconds.",
}) => {
  return (
    <BgLayout>
      <ModalBgLayout>
        <SetupCard
          isLoading
          title={title}
          subtitle={subtitle}
          message={message}
        />
      </ModalBgLayout>
      {background}      
    </BgLayout>
  );
};

export default LoadingPageLayout;
