import React, { useCallback, useMemo } from 'react';
import Input from '../input';
import { useField } from 'formik';

const PercentDecimalInput = ({
  name,
  placeholderText = 'Enter percentage',
  ...props
}) => {
  const [field, , { setValue }] = useField(name);

  // Handle user typing: parse the input as a percentage and store as a decimal
  const handleChange = useCallback(
    (e) => {
      const inputValue = e.target.value;
      // Remove any characters that aren't digits or a dot.
      const sanitizedValue = inputValue.replace(/[^0-9.]/g, '');
      // Allow only one dot by splitting and rejoining.
      const parts = sanitizedValue.split('.');
      const cleanedValue =
        parts.shift() + (parts.length ? '.' + parts.join('') : '');

      const parsedValue = parseFloat(cleanedValue);

      if (!isNaN(parsedValue)) {
        // Convert from percent to decimal
        setValue(parsedValue / 100);
      } else {
        setValue(''); // Clear if invalid
      }
    },
    [setValue]
  );

  // For display, multiply the stored decimal by 100 and round away floating‐point noise
  const displayValue = useMemo(() => {
    if (field.value === '' || field.value == null || isNaN(field.value)) {
      return '';
    }
    // Multiply by 100 (to convert decimal → percentage)
    const multiplied = field.value * 100;
    // Round it (e.g., 44.44000000000005 → "44.44")
    const roundedString = multiplied.toFixed(8); // up to 8 decimals, tweak as needed
    // Convert back to remove trailing zeros (e.g., "44.44000000" → "44.44")
    return parseFloat(roundedString).toString();
  }, [field.value]);

  return (
    <Input
      {...props}
      type="number"
      min='0'
      step='0.01'
      name={name}
      placeholderText={placeholderText}
      value={displayValue}
      onChange={handleChange}
      onBlur={field.onBlur}
      suffix='%'
    />
  );
};

export default PercentDecimalInput;

