import { Form, Formik } from 'formik';
import { HiOutlineMail } from 'react-icons/hi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Button from 'common/components/button';
import FormError from 'common/components/form-error';
import Input from 'common/components/input';
import PasswordInput from 'common/components/password-input';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { setCredentials } from 'redux/features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import BgLayout from 'common/components/bg';
import { Link } from '@chakra-ui/react';
import { ApplyFormField } from 'common/layouts/apply-form';
import WrapInputLabel from 'common/components/wrap-input-label';

const Login = () => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values) => {
        console.log(values);
        login({
          email: values.email,
          password: values.password,
        })
          .unwrap()
          .then((data) => {
            toast.info('Welcome back');
            dispatch(
              setCredentials({
                isLoggedIn: true,
                token: data?.data?.token,
                user: data?.data?.user,
              })
            );
            navigate('/dashboard/home');
          })
          .catch((error) => {
            toast.error(error?.data?.msg || 'Login failed!');
          });
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('invalid email address')
          .required('email is required'),
        password: Yup.string().required('password is required'),
      })}
    >
      {({ values, handleChange, handleBlur, isValid }) => {
        return (
          <BgLayout admin>
            {/* Form */}
            <Form className='flex flex-col items-stretch gap-10'>
              {/* caption */}
              <div className='flex flex-col text-center gap-4 max-w-lg'>
                <h2 className='font-display tracking-tight text-4xl'>
                  ReLease Admin Portal
                </h2>

                <h4 className='text-base font-normal'>
                 Trying to log in as a member? Visit <Link href='https://app.releaserent.com/' className='opacity-50' >app.releaserent.com</Link>
                </h4>
              </div>

              {/* input fields */}
              <div className='flex w-full flex-col items-stretch gap-8'>
                {/* email */}
                <ApplyFormField>
                  <WrapInputLabel isRequired>Email Address</WrapInputLabel>
                  <Input
                    name='email'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    placeholderText='example@gmail.com'
                    Icon={HiOutlineMail}
                  />
                  <FormError name='email' />
                </ApplyFormField>

                <ApplyFormField>
                  <WrapInputLabel isRequired>Password</WrapInputLabel>
                  <PasswordInput
                    name='password'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    placeholderText='Password'
                  />
                  <FormError name='password' />
                </ApplyFormField>
              </div>

              {/* button */}
              <div className='flex flex-col gap-5 max-w-lg'>
                <Button
                  loading={isLoginLoading}
                  disabled={!isValid || isLoginLoading}
                  type='submit'
                  title='Log In'
                />
              </div>
            </Form>
          </BgLayout>
        );
      }}
    </Formik>
  );
};

export default Login;
