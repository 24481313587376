import React from 'react';

const getDocumentName = ({ documentUrl }) => {
  // Get the document name from the url
  if (!documentUrl) return '';
  const regex = /https:\/\/cios-api-images\.s3\.amazonaws\.com\/listings\/(.+)/;
  const match = documentUrl.match(regex);
  const documentName = match ? match[1] : '';

  return documentName;
};

const DocumentPreview = ({ label, url }) => {
  const _label = label || 'uploaded-document';
  return (
    <div className='flex w-full justify-start gap-3 items-center '>
      <span className='text-base font-normal truncate ...'>{_label}</span>
      <button
        onClick={(e) => {
          e.stopPropagation();
          window.open(url, '_blank');
        }}
        className='rounded border border-gray-200 py-0.5 px-2 text-sm font-medium cursor-pointer hover:bg-gray-100 text-nowrap'
      >
        View
      </button>      
    </div>
  );
};

export default DocumentPreview;
