import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const LoadingSpinner = ({
  spinnerColor = '#6B7280',
  width = '40',
  fullWidth = true
}) => {
  const containerClass = `${fullWidth ? 'w-full items-center justify-center' : ''} flex`;
  return (
    <div className={containerClass}>
      <RotatingLines
        strokeColor={spinnerColor}
        strokeWidth='4'
        animationDuration='0.75'
        width={width}
        visible={true}        
      />
    </div>
  );
};

export default LoadingSpinner;
