import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { Global } from "@emotion/react";

// Do not change the overflow sttings on InfoCard
// These are required to prevent react-select from being cut off in the accordion panel, especially in the admin portal

const InfoCard = ({ title, defaultIndex=[0], allowToggle=true, children, className }) => {
  return (
    <>
      <Global
        styles={`
          .chakra-collapse {
            overflow: visible !important;
          }
        `}
      />
      <Accordion 
        defaultIndex={defaultIndex}
        allowToggle={allowToggle} className={`${className} w-full rounded-md border gap-4 border-gray-200 overflow-visible`}
      >
        <AccordionItem className='rounded-t-md overflow-visible border-none'>
          <AccordionButton className='text-base flex w-full justify-between items-center bg-gray-100 font-medium px-4 py-2 rounded-t-md'>
            {title}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel 
            className='border-t border-gray-200 p-0 divide-y divide-gray-200 overflow-visible'
          >
            {children}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>    
  );
};

const InfoGrid = ({ children, className, small=false }) => {
  return (
    <div
      className={`${className} grid w-full ${small ? 'grid-cols-2' : 'grid-cols-4 mobile:grid-cols-2'} gap-4 p-4 `}
    >
      {children}
    </div>
  );
};

const InfoField = ({ title, children, className, onClick, innerClassName }) => {
  return (
    <div className={`${className} flex flex-col gap-1 ${onClick && 'cursor-pointer'}`} onClick={onClick} >
      <label className="text-sm opacity-70">
        {title}
      </label>

      <div className={`${innerClassName} text-base ${onClick && `underline underline-offset-2 opacity-50 hover:opacity-100`}`}>{children}</div>
    </div>
  );
};

const InfoGridView = ({ infoFields, title }) => {  
  return (
    infoFields.some((field) => field.condition) && (
      <InfoGrid>
        {title && (
          <InfoField title={title} className='col-span-4' />
        )}
        {infoFields.map(
          (field, index) =>
            field.condition ? (
              <InfoField key={index} title={field.title} className={field?.className}>
                {field.value}
              </InfoField>
            ) : null
        )}
      </InfoGrid>
    )
  );
};

export { InfoCard, InfoGrid, InfoField, InfoGridView };
