import { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import Button from 'common/components/button';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetPropertyQuery,
  useDeletePropertyMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Toggle from 'common/components/toggle';
import {
  InfoCard,
  InfoField,
  InfoGrid,
  InfoGridView,
} from 'common/components/info-card';
import PropertyType from 'common/components/property-type';
import { StatusLabel } from 'components/properties/status-label';
import { formatPhoneNumber } from 'react-phone-number-input';
import CopyLink from 'common/components/copy-link';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import { FaBuildingColumns, FaCreditCard, FaFile } from 'react-icons/fa6';
import { BsFillFileEarmarkSpreadsheetFill } from 'react-icons/bs';
import DocumentPreview from 'common/components/document-preview';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { calculateAverageRate } from 'common/utils/calculate';
import { formatAsPercentage } from 'common/utils/misc';

const PropertyView = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sortVal, setSort] = useState('-createdAt');

  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetPropertyQuery(propertyId);
  const [
    deleteProperty,
    { isLoading: isDeleteLoading, isFetching: isDeleteFetching },
  ] = useDeletePropertyMutation();

  // Configure all the infofields
  const contactFields = [
    {
      condition: propertyData?.contactName,
      title: 'Contact Name',
      value: propertyData?.contactName,
    },
    {
      condition: propertyData?.email,
      title: 'Contact Email',
      value: propertyData?.email,
    },
    {
      condition: propertyData?.phone?.length > 5,
      title: 'Contact Phone',
      value: formatPhoneNumber(propertyData?.phone),
    },
    {
      condition: propertyData?.website,
      title: 'Website',
      value: propertyData?.website,
    },
  ];

  const addressFields = [
    {
      condition: propertyData?.address.streetAddress1,
      title: 'Street Address 1',
      className: propertyData?.address?.streetAddress2
        ? 'col-span-2'
        : 'col-span-4 mobile:col-span-2',
      value: propertyData?.address.streetAddress1,
    },
    {
      condition: propertyData?.address?.streetAddress2,
      title: 'Street Address 2',
      className: 'col-span-2',
      value: propertyData?.address.streetAddress2,
    },
    {
      condition: propertyData?.address.city,
      title: 'City',
      value: propertyData?.address.city,
    },
    {
      condition: propertyData?.address?.state,
      title: 'State',
      value: propertyData?.address.state,
    },
    {
      condition: propertyData?.address?.province,
      title: 'Province',
      value: propertyData?.address.province,
    },
    {
      condition: propertyData?.address.postalCode,
      title: 'Postal Code',
      value: propertyData?.address.postalCode,
    },
    {
      condition: propertyData?.address.zipCode,
      title: 'Zip Code',
      value: propertyData?.address.zipCode,
    },
    {
      condition: propertyData?.address.country,
      title: 'Country',
      value: propertyData?.address.country,
    },
  ];

  const propertyDefaultsFields = [
    {
      condition:
        propertyData?.propertyDefaults?.terminationPolicy?.noticePeriod,
      title: 'Default Notice Period',
      value: `${propertyData?.propertyDefaults?.terminationPolicy?.noticePeriod} days`,
    },
    {
      condition: propertyData?.propertyDefaults?.terminationPolicy?.penalty,
      title: 'Default Termination Penalty',
      value: FormatAsCurrency(
        propertyData?.propertyDefaults?.terminationPolicy?.penalty,
        propertyData?.paymentInformation?.currency
      ),
    },
    {
      condition:
        propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths,
      title: 'Default Lease Buyout',
      value: `${propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths} months`,
    },
    {
      condition: true,
      title: 'Furnished by Default?',
      value: propertyData?.propertyDefaults?.furnished ? 'Yes' : 'No',
    },
    {
      condition: true,
      title: 'Cancel Anytime Enabled?',
      value: propertyData?.cancelAnytimeEnabled ? 'Yes' : 'No',
    },
    {
      condition: true,
      title: 'Leave Anytime Enabled?',
      value: propertyData?.leaveAnytimeEnabled ? 'Yes' : 'No',
    },
  ];

  const ownerContactFields = [
    {
      condition: propertyData?.owner?.email,
      title: 'Email',
      value: propertyData?.owner?.email,
    },
    {
      condition: propertyData?.owner?.phone,
      title: 'Phone',
      value: formatPhoneNumber(propertyData?.owner?.phone),
    },
    {
      condition: propertyData?.owner?.website,
      title: 'Website',
      value: propertyData?.owner?.website,
    },
  ];

  const paymentInfoFields = [
    {
      condition: propertyData?.paymentInformation?.currency,
      title: 'Currency',
      value: propertyData?.paymentInformation?.currency,
    },
    {
      condition: propertyData?.paymentInformation?.beneficiary,
      title: 'Beneficiary Name',
      value: propertyData?.paymentInformation?.beneficiary,
    },
  ];

  const paymentAccountFields = [
    {
      condition: propertyData?.paymentInformation?.beneficiaryAccountNumber,
      title: 'Beneficiary Account Number',
      value: propertyData?.paymentInformation?.beneficiaryAccountNumber,
    },
    {
      condition: propertyData?.paymentInformation?.beneficiaryRoutingNumber,
      title: 'Beneficiary Routing Number (ABA)',
      value: propertyData?.paymentInformation?.beneficiaryRoutingNumber,
    },
    {
      condition: propertyData?.paymentInformation?.beneficiarySwiftCode,
      title: 'Beneficiary SWIFT Code',
      value: propertyData?.paymentInformation?.beneficiarySwiftCode,
    },
  ];

  const paymentBankFields = [
    {
      condition: propertyData?.paymentInformation?.beneficiaryBankName,
      title: 'Beneficiary Bank Name',
      value: propertyData?.paymentInformation?.beneficiaryBankName,
    },
    {
      condition:
        propertyData?.paymentInformation?.beneficiaryBankAddress
          ?.streetAddress1,
      title: 'Street Address 1',
      value:
        propertyData?.paymentInformation?.beneficiaryBankAddress
          ?.streetAddress1,
      className: propertyData?.paymentInformation?.beneficiaryBankAddress
        ?.streetAddress2
        ? 'col-span-2'
        : 'col-span-4 mobile:col-span-2',
    },
    {
      condition:
        propertyData?.paymentInformation?.beneficiaryBankAddress
          ?.streetAddress2,
      title: 'Street Address 2',
      value:
        propertyData?.paymentInformation?.beneficiaryBankAddress
          ?.streetAddress2,
      className: 'col-span-2',
    },
    {
      condition: propertyData?.paymentInformation?.beneficiaryBankAddress?.city,
      title: 'City',
      value: propertyData?.paymentInformation?.beneficiaryBankAddress?.city,
    },
    {
      condition:
        propertyData?.paymentInformation?.beneficiaryBankAddress?.state,
      title: 'State',
      value: propertyData?.paymentInformation?.beneficiaryBankAddress?.state,
    },
    {
      condition:
        propertyData?.paymentInformation?.beneficiaryBankAddress?.postalCode,
      title: 'Postal Code',
      value:
        propertyData?.paymentInformation?.beneficiaryBankAddress?.postalCode,
    },
    {
      condition:
        propertyData?.paymentInformation?.beneficiaryBankAddress?.country,
      title: 'Country',
      value: propertyData?.paymentInformation?.beneficiaryBankAddress?.country,
    },
  ];

  let plansData = propertyData?.plans || [];
  plansData = plansData.filter((plan) => plan.status);

  // Configure rate fields
  const propertyRates = propertyData?.rates?.underwriting?.rentalUnitBreakdown;

  let averageRate = 0;
  if (propertyRates) {
    averageRate = calculateAverageRate(propertyRates);
    averageRate = averageRate;
  }

  const RateField = ({ rate, title = 'Studio (0bdr)' }) => {
    if (rate?.numberOfUnits > 0) {
      return (
        <InfoField title={`${title} Rate`}>
          {formatAsPercentage(rate?.rate)}%
        </InfoField>
      );
    } else {
      return null;
    }
  };

  return (
    <DashboardWrapperLayout
      title={propertyData?.name || 'Property Details'}
      buttons={
        <Toggle
          selected={'view'}
          editPath={`/dashboard/properties/${propertyData?.id}/edit`}
          viewPath={`/dashboard/properties/${propertyData?.id}`}
        />
      }
    >
      {isGetError ? (
        <EmptyPage
          title='Error Loading Property'
          subtitle='There was an error loading this property. Please try again.'
          buttonText='Reload Page'
          onClick={() => {
            window.location.reload();
          }}
          isError
        />
      ) : isGetLoading ? (
        <EmptyPage
          title='Loading Property Details'
          subtitle='Please wait while we load property details.'
          isLoading
        />
      ) : !propertyData ? (
        <EmptyPage
          title='No Property Found'
          subtitle='This property does not exist.'
          buttonText={'Go Back'}
          onClick={() => {
            navigate('/dashboard/properties');
          }}
        />
      ) : (
        <>
          {/* Delete property */}
          {deleteModalOpen && (
            <ConfirmActionModal
              action={`delete this property`}
              onConfirmClick={() => {
                deleteProperty({ id: propertyId })
                  .unwrap()
                  .then(() => {
                    navigate('/dashboard/properties');
                  })
                  .catch((e) => {
                    toast.error(e?.data?.msg || 'Delete Failed');
                  });
              }}
              onCancel={() => {
                setDeleteModalOpen(false);
              }}
            />
          )}

          {/* Action buttons */}
          <FloatingButtons>
            <Button
              onClick={() => {
                navigate(`/dashboard/plans/new?propertyId=${propertyData?.id}`);
              }}
              title='Add Plan'
              wFull={false}
            />
            {propertyData?.id &&
            propertyData?.rates?.underwriting?.rentalUnitBreakdown ? (
              <>
                <Button
                  onClick={() => {
                    navigate(`/dashboard/properties/${propertyData?.id}/rates`);
                  }}
                  title='View Rates'
                  wFull={false}
                />
                <Button
                  onClick={() => {
                    navigate(
                      `/dashboard/properties/${propertyData?.id}/rates/edit`
                    );
                  }}
                  title='Change Rates'
                  wFull={false}
                />
              </>
            ) : (
              <Button
                onClick={() => {
                  navigate(
                    `/dashboard/properties/${propertyData?.id}/rates/edit`
                  );
                }}
                title='Add Rates'
                wFull={false}
              />
            )}
            {propertyData?.owner && (
              <Button
                onClick={() => {
                  navigate(`/dashboard/owners/${propertyData?.owner?.id}`);
                }}
                title='Company Details'
                wFull={false}
                theme='secondary'
              />
            )}
            {/* Delete button commented out for now */}
            <Button
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              theme='secondary'
              title='Delete Property'
              wFull={false}
            />
          </FloatingButtons>

          {/* Property Details Table */}
          <InfoCard title='Property Information'>
            <InfoGrid>
              <InfoField title='Name'>{propertyData?.name}</InfoField>
              <InfoField title='Property Number'>
                <CopyLink text={propertyData?.id} title='Property Number' />
              </InfoField>
              <InfoField title='Status'>
                <StatusLabel status={propertyData?.status} />
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Property Type'>
                <PropertyType rentalType={propertyData?.kind} />
              </InfoField>
              <InfoField title='Billing Preference'>
                <StatusLabel status={propertyData?.billing} />
              </InfoField>
            </InfoGrid>
            <InfoGridView infoFields={contactFields} />
            <InfoGridView infoFields={addressFields} />

            {/* Notes */}
            {propertyData?.notes ? (
              <InfoGrid>
                <InfoField title='Notes' className='col-span-4'>
                  {propertyData?.notes}
                </InfoField>
              </InfoGrid>
            ) : null}
          </InfoCard>

          {/* Rates */}
          {propertyRates ? (
            <InfoCard title='Property Rates'>
              <InfoGrid>
                <InfoField title='Status'>
                  <StatusLabel status={propertyData?.rates?.status} />
                </InfoField>
                {averageRate > 0 ? (
                  <InfoField title='Average Rate'>
                    {formatAsPercentage(averageRate)}%
                  </InfoField>
                ) : null}
                {propertyData?.rates?.underwriting?.date ? (
                  <InfoField title='Underwriting Date'>
                    {formatDateToHumanDate(
                      propertyData?.rates?.underwriting?.date
                    )}
                  </InfoField>
                ) : null}
                <InfoField title='Partner Term Sheet'>
                  <DocumentPreview
                    label={`${propertyData?.name} Term Sheet`}
                    url={propertyData?.rates?.partnerTermSheet}
                  />
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <RateField rate={propertyRates?.['0']} />
                <RateField rate={propertyRates?.['1']} title='1 Bedroom' />
                <RateField rate={propertyRates?.['2']} title='2 Bedroom' />
                <RateField rate={propertyRates?.['3']} title='3 Bedroom' />
                <RateField rate={propertyRates?.['4+']} title='4+ Bedroom' />
              </InfoGrid>
            </InfoCard>
          ) : (
            <EmptyPage
              title='Not Yet Rated'
              subtitle='This property has not been rated yet.'
              PageIcon={BsFillFileEarmarkSpreadsheetFill}
            />
          )}

          {/* Lease Defaults */}
          <InfoCard title='Property-Wide Lease Defaults'>
            <InfoGridView infoFields={propertyDefaultsFields} />
          </InfoCard>

          {/* Owner Information */}
          {propertyData?.owner?.id ? (
            <InfoCard title='Owner Information'>
              <InfoGrid>
                <InfoField title='Name'>{propertyData?.owner?.name}</InfoField>
                <InfoField title='Status'>
                  {propertyData?.owner?.status}
                </InfoField>
                <InfoField title='ID' className='uppercase'>
                  <CopyLink text={propertyData?.owner?.id} title='Owner ID' />
                </InfoField>
              </InfoGrid>
              <InfoGridView infoFields={ownerContactFields} />
              {propertyData?.owner?.notes ? (
                <InfoGrid>
                  <InfoField title='Notes' className='col-span-4'>
                    {propertyData?.owner?.notes}
                  </InfoField>
                </InfoGrid>
              ) : null}
            </InfoCard>
          ) : (
            <EmptyPage
              title='No Owner Information'
              subtitle='This property does not have an owner assigned.'
              PageIcon={FaBuildingColumns}
            />
          )}

          {/* Payment Information */}
          {propertyData?.paymentInformation ? (
            <InfoCard title='Payment Information'>
              <InfoGridView infoFields={paymentInfoFields} />
              <InfoGridView infoFields={paymentAccountFields} />
              <InfoGridView infoFields={paymentBankFields} />
            </InfoCard>
          ) : (
            <EmptyPage
              title='No Payment Information'
              subtitle='This property does not have payment information.'
              PageIcon={FaCreditCard}
            />
          )}

          {/* Documents shared for this property */}
          {propertyData?.documents?.length > 0 ? (
            <div className='flex flex-col gap-4'>
              <h2 className='text-lg font-medium'>
                Shared Documents from Owner
              </h2>
              <table className='w-full text-base'>
                <tbody className='flex flex-col rounded-md border border-gray-200 divide-y divide-gray-200'>
                  {propertyData?.documents?.map((doc, index) => (
                    <tr
                      key={index}
                      className='grid grid-cols-[1fr_auto] items-center px-4 gap-3'
                    >
                      <td className='py-2 text-base font-medium'>{doc.name}</td>
                      <td className='py-2'>
                        <Button
                          title='View Document'
                          theme='secondary'
                          onClick={() => {
                            window.open(doc.url, '_blank');
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <EmptyPage
              title='No Shared Documents'
              subtitle='There are no shared documents.'
              PageIcon={FaFile}
            />
          )}

          {/* Plans at this property */}
          {isEmpty(plansData) ? (
            <EmptyPage
              title='No Plans Under Property'
              subtitle='This property does not have any plans.'
            />
          ) : (
            <div className='flex flex-col gap-4'>
              <h2 className='text-lg font-medium'>Property Plans</h2>
              <PlansTable
                showMember={true}
                plans={plansData}
                setSort={setSort}
                sortVal={sortVal}
              />
            </div>
          )}
        </>
      )}
    </DashboardWrapperLayout>
  );
};

export default PropertyView;
