import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import PageNotFound from 'pages/PageNotFound';
import ClaimEdit from 'pages/dashboard/claims/claimEdit';
import ClaimView from 'pages/dashboard/claims/claimView';
import ClaimFind from 'pages/dashboard/claims/claimFind';
import Home from 'pages/dashboard/home/Home';
import MemberView from 'pages/dashboard/members/memberView';
import MemberEdit from 'pages/dashboard/members/memberEdit';
import MemberFind from 'pages/dashboard/members/memberFind';
import PlanEdit from 'pages/dashboard/plans/planEdit';
import PlanView from 'pages/dashboard/plans/planView';
import PlanFind from 'pages/dashboard/plans/planFind';
import RouteGuard from 'utils/routeGuard';
import Login from 'pages/auth/Login';
import PropertyFind from 'pages/dashboard/properties/propertyFind';
import PropertyView from 'pages/dashboard/properties/propertyView';
import PropertyEdit from 'pages/dashboard/properties/propertyEdit';
import PropertyRatesEdit from 'pages/dashboard/properties/propertyRatesEdit';
import OwnerFind from 'pages/dashboard/owners/ownerFind';
import OwnerEdit from 'pages/dashboard/owners/ownerEdit';
import OwnerView from 'pages/dashboard/owners/ownerView';
import InvoiceEdit from 'pages/dashboard/invoices/InvoiceEdit';
import InvoiceView from 'pages/dashboard/invoices/InvoiceView';
import OwnerFindInvoice from 'pages/dashboard/invoices/OwnerFindInvoice';
import PropertyRatesView from 'pages/dashboard/properties/propertyRatesView';
import PlanClaims from 'pages/dashboard/plans/planClaims';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Not Found/ Invalid Route */}
        <Route path='*' element={<PageNotFound />} />

        <Route element={<RouteGuard />}>
          <Route path='/login' element={<Login />} />

          {/* Home */}
          <Route path='/' element={<Navigate to='/dashboard/home' replace />} />
          <Route
            path='/dashboard'
            element={<Navigate to='/dashboard/home' replace />}
          />
          {/* Preferred route */}
          <Route path='/dashboard/home' element={<Home />} />

          {/* Claims */}
          <Route path='/dashboard/claims' element={<ClaimFind />} />
          <Route path='/dashboard/claims/:claimId' element={<ClaimView />} />
          <Route
            path='/dashboard/claims/:claimId/edit'
            element={<ClaimEdit />}
          />

          {/* Plans */}
          <Route path='/dashboard/plans' element={<PlanFind />} />
          <Route path='/dashboard/plans/new' element={<PlanEdit />} />
          <Route path='/dashboard/plans/:planId' element={<PlanView />} />
          <Route path='/dashboard/plans/:planId/edit' element={<PlanEdit />} />
          <Route path='/dashboard/plans/:planId/claims' element={<PlanClaims />} />

          {/* Owners  */}
          <Route path='/dashboard/owners' element={<OwnerFind />} />
          <Route path='/dashboard/owners/new' element={<OwnerEdit />} />
          <Route path='/dashboard/owners/:ownerId' element={<OwnerView />} />
          <Route
            path='/dashboard/owners/:ownerId/edit'
            element={<OwnerEdit />}
          />
          <Route
            path='/dashboard/owners/:ownerId/invoices/:billingYear/:billingMonth'
            element={<OwnerFindInvoice />}
          />

          {/* Members */}
          <Route path='/dashboard/members/new' element={<MemberEdit />} />
          <Route
            path='/dashboard/members/:memberId/edit'
            element={<MemberEdit />}
          />
          <Route path='/dashboard/members/:memberId' element={<MemberView />} />
          <Route path='/dashboard/members' element={<MemberFind />} />

          {/* invoices */}
          <Route
            path='/dashboard/invoices/:invoiceId'
            element={<InvoiceView />}
          />
          <Route
            path='/dashboard/invoices/:invoiceId/edit'
            element={<InvoiceEdit />}
          />          

          {/* Properties */}
          <Route
            path='/dashboard/properties/:propertyId/edit'
            element={<PropertyEdit />}
          />          
          <Route path='/dashboard/properties/new' element={<PropertyEdit />} />
          <Route
            path='/dashboard/properties/:propertyId'
            element={<PropertyView />}
          />
          <Route path='/dashboard/properties' element={<PropertyFind />} />
          <Route
            path='/dashboard/properties/:propertyId/rates'
            element={<PropertyRatesView />}
          />
          <Route
            path='/dashboard/properties/:propertyId/rates/edit'
            element={<PropertyRatesEdit />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
