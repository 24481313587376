import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorData from 'components/shared/error-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import * as Yup from 'yup';
import Input from 'common/components/input';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetTenantQuery,
  useUploadRequestUrlMutation,
  useUpdateTenantMutation,
  useCreateTenantMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { Form, Formik } from 'formik';
import SelectInput from 'common/components/select-input';
import { tenantStatusEnum, documentStatusEnum } from 'common/utils/enums';
import { useNavigate } from 'react-router-dom';
import FormError from 'common/components/form-error';
import { uploadFileToS3 } from 'utils/uploadS3';
import FileUploadInput from 'common/components/file-upload-input';
import {
  tenantStatusOptions,
  documentStatusOptions,
  verificationStatusOptions,
} from 'common/utils/selectOptions';
import Button from 'common/components/button';
import Toggle from 'common/components/toggle';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-international-phone/style.css';
import { PhoneNumberInput } from 'common/components/phone-input';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';

const MemberEdit = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const isNew = !memberId;

  const [
    createTenant,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useCreateTenantMutation();
  const [
    updateTenant,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdateTenantMutation();
  const {
    isError: isGetError,
    error: getError,
    data: tenantData,
  } = useGetTenantQuery(memberId, { skip: !memberId });

  if (isGetError) {
    toast.error(getError?.data?.msg || 'Failed to fetch member details');
    return (
      <DashboardWrapperLayout title={'Failed to Fetch Member Details'}>
        <EmptyPage
          isError
          title='Failed to fetch member details'
          subtitle={getError?.data?.msg || 'Failed to fetch member details'}
          buttonText={'Reload Page'}
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }

  if (!isNew && !tenantData) {
    return (
      <DashboardWrapperLayout title={'Loading Member Details'}>
        <EmptyPage
          isLoading
          title='Loading Member Details'
          subtitle='Please wait while we load the Member details.'
        />
      </DashboardWrapperLayout>
    );
  }

  // Initialize data

  const userDocs = tenantData?.documents;
  const docLength = userDocs?.length;
  let idDocumentUrl = '';

  if (docLength) {
    idDocumentUrl = userDocs[docLength - 1]?.url;
  }

  return (
    <Formik
      onSubmit={(value) => {
        if (isNew) {
          createTenant(value)
            .unwrap()
            .then((data) => {
              console.log(data);
              navigate(`/dashboard/members/${data?.id}`);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Create Failed');
            });
        } else {
          updateTenant({ ...value, id: tenantData.id })
            .unwrap()
            .then(() => {
              navigate(-1);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Update Failed');
            });
        }
      }}
      initialValues={{
        name: tenantData?.name || '',
        firstName: tenantData?.firstName || '',
        lastName: tenantData?.lastName || '',
        email: tenantData?.email || '',
        phone: tenantData?.phone,
        status: tenantData?.status || tenantStatusEnum.NEW,
        idUrl: '',
        idVerification: tenantData?.idVerification || documentStatusEnum.NONE,
        phoneConfirmed: tenantData?.phoneConfirmed ? true : false,
        emailConfirmed: tenantData?.emailConfirmed ? true : false,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
        phone: Yup.string()
          .nullable()
          .test('is-valid-phone', 'Phone number is invalid', (value) => {
            if (!value) return true; // skip validation if empty
            return isValidPhoneNumber(value);
          }),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        dirty: isDirty,
        errors,
        setFieldValue,
      }) => {
        console.log(values);
        return (
          <Form>
            <DashboardWrapperLayout
              title={
                tenantData?.id
                  ? `${tenantData?.firstName} ${tenantData?.lastName}`
                  : `New Member`
              }
              buttons={
                tenantData?.id && (
                  <Toggle
                    selected={'edit'}
                    editPath={`/dashboard/members/${tenantData?.id}/edit`}
                    viewPath={`/dashboard/members/${tenantData?.id}`}
                  />
                )
              }
            >
              <>
                {/* Action buttons */}
                <FloatingButtons
                  button={
                    <Button
                      disabled={!isValid || !isDirty}
                      // Icon={IoMdSave}
                      onClick={handleSubmit}
                      title='Save Changes'
                      wFull={false}
                      className='px-6'
                    />
                  }
                />

                {/* Member Edit Table */}
                <InfoCard title='Member Information'>
                  <InfoGrid>
                    <InfoField title='First Name'>
                      <Input
                        placeholderText='FirstName'
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='firstName'
                      />
                      <FormError name='firstName' />
                    </InfoField>
                    <InfoField title='Last Name'>
                      <Input
                        placeholderText='LastName'
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lastName'
                      />
                      <FormError name='lastName' />
                    </InfoField>                    
                  </InfoGrid>
                  <InfoGrid>                  
                    <InfoField title='Email' className='col-span-2'>
                      <Input
                        placeholderText='member@releaserent.com'
                        type='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='email'
                      />
                      <FormError name='email' />
                    </InfoField>
                    <InfoField title='Phone' className='col-span-2'>
                      <PhoneNumberInput
                        name='phone'
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue('phone', e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='phone' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Member Status'>
                      <SelectInput
                        options={tenantStatusOptions}
                        placeholder='Select Status'
                        name='values.status'
                        value={tenantStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('status', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='ID Verificiation'>
                      <SelectInput
                        options={documentStatusOptions}
                        placeholder='Select Verification Status'
                        name='idVerification'
                        value={documentStatusOptions.find(
                          (status) => status.value === values.idVerification
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'idVerification',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Email Verification'>
                      <SelectInput
                        options={verificationStatusOptions}
                        placeholder='Select Status'
                        name='values.status'
                        value={verificationStatusOptions.find(
                          (status) => status.value === values.emailConfirmed
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'emailConfirmed',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Phone Verification'>
                      <SelectInput
                        options={verificationStatusOptions}
                        placeholder='Select Status'
                        name='values.status'
                        value={verificationStatusOptions.find(
                          (status) => status.value === values.phoneConfirmed
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'phoneConfirmed',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Upload New ID' className='col-span-2'>
                      <FileUploadInput
                        disableUpload={disableFileUpload}
                        isUploadError={uploadStatus.isError}
                        isUploadLoading={uploadStatus.isLoading}
                        isUploadSuccess={uploadStatus.isSuccess}
                        handlePDFClear={() => {
                          setFieldValue('idUrl', '');
                        }}
                        handlePDFUpload={async (file) => {
                          let uploadUrlReq;
                          setUploadStatus({
                            isSuccess: false,
                            isLoading: true,
                            isError: false,
                          });

                          // Get the signing url for uploading to s3
                          try {
                            uploadUrlReq = await uploadRequestUrl({
                              fileName: file.name,
                              contentType: file.type,
                            }).unwrap();
                          } catch (e) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error(
                              e?.msg ||
                                e?.message ||
                                'failed to upload document'
                            );
                            return;
                          }

                          if (
                            !uploadUrlReq?.data?.uploadUrl ||
                            !uploadUrlReq?.data?.viewUrl
                          ) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error('failed to upload document');
                          }

                          // Upload the file to s3

                          try {
                            await uploadFileToS3({
                              s3Url: uploadUrlReq?.data?.uploadUrl,
                              file,
                            });

                            // Have to set the uploaded file url on the lease document
                            setUploadStatus({
                              isSuccess: true,
                              isError: false,
                              isLoading: false,
                            });
                            setDisableFileUpload(true);
                            setFieldValue('idUrl', uploadUrlReq?.data?.viewUrl);
                            toast.info(
                              uploadUrlReq?.status ||
                                'file has been uploaded successfully'
                            );
                          } catch (e) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error(
                              e?.msg ||
                                e?.message ||
                                'failed to upload document'
                            );
                            return;
                          }
                        }}
                      />
                      <FormError name='idFile' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>
              </>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MemberEdit;
