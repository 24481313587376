import { useParams } from 'react-router-dom';
import 'react-international-phone/style.css';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Input from 'common/components/input';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetOwnerQuery,
  useUpdateOwnerMutation,
  useCreateOwnerMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { Form, Formik, Field } from 'formik';
import SelectInput from 'common/components/select-input';
import { ownerStatusEnum } from 'common/utils/enums';
import { useNavigate } from 'react-router-dom';
import FormError from 'common/components/form-error';
import {
  ownerStatusOptions,
  ownerKindOptions,
} from 'common/utils/selectOptions';
import Button from 'common/components/button';
import Toggle from 'common/components/toggle';
import { PhoneNumberInput } from 'common/components/phone-input';
import TextAreaInput from 'common/components/text-area-input';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import PercentDecimalInput from 'common/components/percent-decimal-input';

const OwnerEdit = () => {
  const { ownerId } = useParams();
  const navigate = useNavigate();
  const isNew = !ownerId;

  const [
    createOwner,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useCreateOwnerMutation();
  const [
    updateOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdateOwnerMutation();
  const {
    isError: isGetError,
    error: getError,
    data: ownerData,
  } = useGetOwnerQuery(ownerId, { skip: !ownerId });

  if (isGetError) {
    toast.error(getError?.data?.msg || 'Failed to fetch owner details');
    return (
      <DashboardWrapperLayout
        title={ownerData?.name || 'Failed to Fetch Owner Details'}
      >
        <EmptyPage
          isError
          title='Failed to fetch owner details'
          subtitle={getError?.data?.msg || 'Failed to fetch owner details'}
          buttonText={'Reload Page'}
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }

  if (!isNew && !ownerData) {
    return (
      <DashboardWrapperLayout title={'Loading Owner Details'}>
        <EmptyPage
          isLoading
          title='Loading Owner Details'
          subtitle='Please wait while we load the owner details.'
        />
      </DashboardWrapperLayout>
    );
  }

  return (
    <DashboardWrapperLayout
      title={ownerData?.name || 'Owner Details'}
      buttons={
        ownerId && (
          <Toggle
            selected={'edit'}
            editPath={`/dashboard/owners/${ownerData?.id}/edit`}
            viewPath={`/dashboard/owners/${ownerData?.id}`}
          />
        )
      }
    >
      <Formik
        onSubmit={(value) => {
          let valueCp = JSON.parse(JSON.stringify(value));
          if (isNew) {
            createOwner(valueCp)
              .unwrap()
              .then((data) => {
                toast.success('Owner Created Successfully');
                navigate(`/dashboard/owners/${data?.id}`);
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Create Failed');
              });
          } else {
            updateOwner({ ...valueCp, id: ownerData.id })
              .unwrap()
              .then(() => {
                navigate(-1);
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Update Failed');
              });
          }
        }}
        initialValues={{
          name: ownerData?.name || '',
          email: ownerData?.email || '',
          phone: ownerData?.phone || '',
          website: ownerData?.website || '',
          notes: ownerData?.notes || '',          
          status: ownerData?.status || ownerStatusEnum.ACTIVE,
          program: {
            coverageLimit: {
              amount: ownerData?.program?.coverageLimit?.amount || 30000,
              months: ownerData?.program?.coverageLimit?.months || 6,
            },
            deductible: ownerData?.program?.deductible || 500,
            requiredStay: ownerData?.program?.requiredStay || 30,
            revShareRate: ownerData?.program?.revShareRate || 0,
            flatCommission: ownerData?.program?.flatCommission || 0,
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          email: Yup.string(),
          phone: Yup.string(),
          website: Yup.string(),
          notes: Yup.string(),
          status: Yup.string().required('Required'),
          program: Yup.object().shape({
            coverageLimit: Yup.object().shape({
              amount: Yup.number(),
              months: Yup.number(),
            }),
            deductible: Yup.number(),
            requiredStay: Yup.number(),
            revShareRate: Yup.number(),
            flatCommission: Yup.number(),
          }),
        })}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
          values,
          dirty: isDirty,
          errors,
          setFieldValue,
        }) => {
          return (
            <Form className='flex flex-col w-full gap-8'>
              <>
                {/* Action buttons */}
                <FloatingButtons
                  button={
                    <Button
                      disabled={!isValid || !isDirty}
                      onClick={handleSubmit}
                      title='Save Changes'
                      wFull={false}
                    />
                  }
                />

                {/* Owner Edit Table */}
                <InfoCard title='Owner Information'>
                  <InfoGrid>
                    <InfoField title='Name' className='col-span-2'>
                      <Input
                        placeholderText='Company Name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='name'
                      />
                      <FormError name='name' />
                    </InfoField>
                    <InfoField title='Status'>
                      <SelectInput
                        options={ownerStatusOptions}
                        placeholder='Select Status'
                        name='values.status'
                        value={ownerStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('status', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Email' className='col-span-2'>
                      <Input
                        placeholderText='Email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='email'
                      />
                      <FormError name='email' />
                    </InfoField>
                    <InfoField
                      title='Phone'
                      className='col-span-2 sm:col-span-1'
                    >
                      <PhoneNumberInput
                        name='phone'
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue('phone', e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='phone' />
                    </InfoField>
                    <InfoField
                      title='Website'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        placeholderText='Website'
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='website'
                      />
                      <FormError name='website' />
                    </InfoField>
                  </InfoGrid>                  
                  <InfoGrid>
                    <InfoField title='Notes' className='col-span-4'>
                      <TextAreaInput
                        placeholderText='Notes'
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='notes'
                      />
                      <FormError name='notes' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                {/* Program Information */}
                <InfoCard title='Program Information'>
                  <InfoGrid>
                    <InfoField title='Coverage Limit Amount' >
                      <Input
                        placeholderText='Amount'
                        value={values.program.coverageLimit.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='program.coverageLimit.amount'
                        prefix={'$'}
                      />
                      <FormError name='program.coverageLimit.amount' />
                    </InfoField>
                    <InfoField title='Months'>
                      <Input
                        placeholderText='Coverage Limit Months'
                        value={values.program.coverageLimit.months}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='program.coverageLimit.months'
                        suffix={'Months'}
                      />
                      <FormError name='program.coverageLimit.months' />
                    </InfoField>
                    <InfoField title='Deductible'>
                      <Input
                        placeholderText='Deductible'
                        value={values.program.deductible}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='program.deductible'
                        prefix={'$'}
                      />
                      <FormError name='program.deductible' />
                    </InfoField>
                    <InfoField title='Required Stay'>
                      <Input
                        placeholderText='Required Stay'
                        value={values.program.requiredStay}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='program.requiredStay'
                        suffix={'Days'}
                      />
                      <FormError name='program.requiredStay' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Rev Share Rate'>
                      <Field name='program.revShareRate'>
                        {({ field, form }) => (
                          <PercentDecimalInput
                            {...field}
                            placeholderText='Enter percentage'
                          />
                        )}
                      </Field>
                      <FormError name='program.revShareRate' />
                    </InfoField>
                    <InfoField title='Flat Commission'>
                      <Input
                        type='number'
                        placeholderText='Flat Comission'
                        value={values.program.flatCommission}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='program.flatCommission'
                        prefix='$'
                        suffix='/Plan'
                      />
                      <FormError name='program.flatCommission' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>
              </>
            </Form>
          );
        }}
      </Formik>
    </DashboardWrapperLayout>
  );
};

export default OwnerEdit;
