import React from 'react';
import { useParams } from 'react-router-dom';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { useGetInvoiceQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import EmptyPage from 'common/components/empty-page';

const InvoiceView = () => {
  const { invoiceId } = useParams();

  const {
    data: invoiceData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
  } = useGetInvoiceQuery(invoiceId);

  if (isGetError) {
    return (
      <DashboardWrapperLayout title='Invoice Details'>
        <EmptyPage
          isError={isGetError}
          title='Error Loading Invoice'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }

  if (isGetLoading) {
    return (
      <DashboardWrapperLayout title='Invoice Details'>
        <EmptyPage
          title='Loading Invoice Details'
          subtitle='Please wait while we load the invoice details.'
          isLoading
        />
      </DashboardWrapperLayout>
    );
  }

  if (!invoiceData) {
    return (
      <DashboardWrapperLayout title='Invoice Details'>
        <EmptyPage
          title='Invoice Not Found'
          subtitle='The requested invoice does not exist.'
        />
      </DashboardWrapperLayout>
    );
  }

  return (
    <DashboardWrapperLayout title='Invoice Details'>
      <InfoCard title='Invoice Information'>
        <InfoGrid>
          <InfoField title='Invoice ID'>{invoiceData.id}</InfoField>
          <InfoField title='Owner ID'>{invoiceData.owner}</InfoField>
          <InfoField title='Total'>{invoiceData.total}</InfoField>
        </InfoGrid>
        {/* Add more InfoFields here to display other invoice details */}
      </InfoCard>
      {/*Display Plans Information, Adjustments, etc. Here */}
    </DashboardWrapperLayout>
  );
};

export default InvoiceView;
