import { useEffect, useRef } from 'react';
import { propertyBillingEnum } from 'common/utils/enums';

export function isPropertyBulkBilling(property) {
  return property?.billing === propertyBillingEnum.BULK;
}

export function doesClaimRequirePayment(claim) {
  if (claim && claim.memberPayment) {
    let memberPayments =
      claim?.memberPayment?.deductible +
      claim?.memberPayment?.nonEligibleCharges;
    return Boolean(memberPayments > 0);
  }
  return false;
}

// Function to convert strings into true title case (e.g. states)
export function toTitleCase(str) {
  const exceptions = ["of", "and", "the"];
  if (str) {
    return str
      .toLowerCase()
      .replace(/[_-]+/g, " ")
      .split(" ")
      .map((word, i) =>
        i === 0 || !exceptions.includes(word)
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word,
      )
      .join(" ");
  } else {
    return "";
  }    
}

// Safe number conversion - returns 0 if value is empty, null, or undefined
export const safeNumberConversion = (value) => {
  if (value === "" || value === null || value === undefined) return 0;
  const num = parseFloat(value);
  return isNaN(num) ? 0 : num;
};

// Turn decimal into percentage
export const formatAsPercentage = (decimal) => {
  let percentage = safeNumberConversion(decimal) * 100;
  percentage = percentage.toFixed(2);
  return percentage;
};

// Deep compare effect hook
function isEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function useDeepCompareEffect(callback, dependencies) {
  const previousDepsRef = useRef();

  if (!isEqual(previousDepsRef.current, dependencies)) {
    previousDepsRef.current = dependencies;
  }

  useEffect(callback, [previousDepsRef.current]);
}
