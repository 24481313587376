import React from 'react';

const ModalBgLayout = ({ children }) => {
  return (
    <div className='fixed overflow-auto inset-0 left-0 top-0 w-full h-full flex items-center justify-center z-50 p-4 bg-black bg-opacity-50 mobile:p-0'>
      {/* wrapper */}
      <div className='bg-white rounded-lg shadow-xl p-6 max-w-xl mobile:rounded-none mobile:fixed mobile:top-0 mobile:left-0 mobile:right-0 mobile:bottom-0 mobile:w-full mobile:h-full mobile:max-w-none mobile:overflow-scroll mobile:h-[100vh] mobile:pt-16'>
        {children}
      </div>
    </div>
  );
};

export default ModalBgLayout;
