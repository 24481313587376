import React from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { toDatePickerFormat } from 'common/utils/toDatePickerFormat';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import SelectInput from 'common/components/select-input';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetClaimQuery,
  useGetPropertyQuery,
  useUpdateClaimMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import {
  claimStatusEnum,
  claimTypeEnum,
  claimPaymentPreferenceEnum,
} from 'common/utils/enums';
import Toggle from 'common/components/toggle';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import Input from 'common/components/input';
import TextAreaInput from 'common/components/text-area-input';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { claimStatusOptions } from 'common/utils/selectOptions';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';

const ClaimEdit = () => {
  const navigate = useNavigate();
  const { claimId } = useParams();

  const [
    updateClaim,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdateClaimMutation();
  const {
    data: claimData,
    isError: isGetError,
    error: getError,
  } = useGetClaimQuery(claimId);

  const {
    data: propertyData,
    isError: isPropertyError,
    error: getPropertyError,
  } = useGetPropertyQuery(claimData?.propertyId, { skip: !claimData?.propertyId });

  // Calculate total concessions
  const lease = claimData?.planId?.lease;
  const concessions =
  lease?.concessions?.repayable
    ? (lease.concessions.months ?? 0) *
      (lease.monthlyRent ?? 0) *
      (lease.concessions.discount ?? 0)
    : 0;

  if (isGetError) {
    toast.error(getError?.data?.msg || 'Failed to fetch claim details');
    return (
      <DashboardWrapperLayout title={'Failed to Fetch Claim Details'}>
        <EmptyPage
          isError
          title='Failed to fetch claim details'
          subtitle={getError?.data?.msg || 'Failed to fetch claim details'}
          buttonText={'Reload Page'}
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }

  if (!claimData || isUpdateLoading || isUpdateFetching) {
    return (
      <DashboardWrapperLayout title={'Loading Claim Details'}>
        <EmptyPage
          isLoading
          title='Loading Claim Details'
          subtitle='Please wait while we load the claim details.'
        />
      </DashboardWrapperLayout>
    );
  }

  const { userId: userData, planId: planData } = claimData;

  const { status: claimStatus } = claimData;

  return (
    <Formik
      onSubmit={(values) => {
        let submissionValues = {
          id: claimId,
          status: values.status,
        };

        if (values.review.reason) {
          submissionValues.review = {
            reason: values.review.reason,
          };
        }

        if (
          values.review.dates.moveOutStart &&
          values.review.dates.moveOutEnd
        ) {
          submissionValues.review = {
            ...submissionValues.review,
            dates: {
              moveOutStart: values.review.dates.moveOutStart,
              moveOutEnd: values.review.dates.moveOutEnd,
            },
          };
        }

        if (values.submission.confirmedDate) {
          submissionValues.submission = {
            confirmedDate: values.submission.confirmedDate,
          };
        }

        if (
          values.memberPayment.deductible !== undefined &&
          values.memberPayment.nonEligibleCharges !== undefined
        ) {
          submissionValues.memberPayment = {
            deductible: values.memberPayment.deductible,
            nonEligibleCharges: values.memberPayment.nonEligibleCharges,
          };
        }

        console.log('submissionValues: ', submissionValues);

        updateClaim(submissionValues)
          .unwrap()
          .then((data) => {
            navigate(`/dashboard/claims/${data?.id}`);
          })
          .catch((e) => {
            toast.error(e?.data?.msg || 'Update Failed');
          });
      }}
      initialValues={{
        status: claimStatus,
        submission: {
          confirmedDate:
            claimData?.submission?.confirmedDate &&
            toDatePickerFormat(claimData?.submission?.confirmedDate),
        },
        review: {
          dates: {
            moveOutStart:
              claimData?.review?.dates?.moveOutStart &&
              toDatePickerFormat(claimData?.review?.dates?.moveOutStart),
            moveOutEnd:
              claimData?.review?.dates?.moveOutEnd &&
              toDatePickerFormat(claimData?.review?.dates?.moveOutEnd),
          },
          reason: claimData?.review?.reason,
        },
        memberPayment: {
          deductible: claimData?.memberPayment?.deductible || propertyData?.program?.deductible,
          nonEligibleCharges: claimData?.memberPayment?.nonEligibleCharges || concessions,
        },
      }}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        dirty: isDirty,
        values,
        setFieldValue,
      }) => {
        return (
          <DashboardWrapperLayout
            title={`Edit Claim Details`}
            buttons={
              <Toggle
                selected={'edit'}
                editPath={`/dashboard/claims/${claimId}/edit`}
                viewPath={`/dashboard/claims/${claimId}`}
              />
            }
          >
            {/* Main Content */}
            <>
              {/* Action Buttons */}
              <FloatingButtons
                button={
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmit}
                    title='Save Changes'
                    wFull={false}
                    className='px-6'
                  />
                }
              />

              {/* Editable Fields */}
              <InfoCard title='Change Claim Status'>
                <InfoGrid>
                  <InfoField title='Status' className='col-span-2'>
                    <SelectInput
                      options={claimStatusOptions}
                      placeholder='Select Status'
                      name='status'
                      value={claimStatusOptions.find(
                        (status) => status.value === values.status
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('status', selectedOption?.value || '');
                      }}
                      onBlur={handleBlur}
                    />
                  </InfoField>
                </InfoGrid>

                {/* Approved Dates */}
                {/* Only show if status is set to review, resolved, confirmed */}
                {[
                  claimStatusEnum.IN_REVIEW,
                  claimStatusEnum.RESOLVED,
                  claimStatusEnum.CONFIRMED,
                ].includes(values.status) && (
                  <>
                    <InfoGrid>
                      <InfoField title='Approved Earliest Move Out Date'>
                        <Input
                          type='date'
                          value={values?.review?.dates?.moveOutStart}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='review.dates.moveOutStart'
                        />
                      </InfoField>
                      <InfoField title='Approved Latest Move Out Date'>
                        <Input
                          type='date'
                          value={values?.review?.dates?.moveOutEnd}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='review.dates.moveOutEnd'
                        />
                      </InfoField>
                      <InfoField title='Deductible'>
                        <Input
                          type='number'
                          placeholderText='Enter Number'
                          value={values?.memberPayment?.deductible}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='memberPayment.deductible'
                          prefix='$'
                          suffix={planData.lease.currency}
                        />
                      </InfoField>
                      <InfoField title='Non-Eligible Charges'>
                        <Input
                          type='number'
                          value={values?.memberPayment?.nonEligibleCharges}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='memberPayment.nonEligibleCharges'
                          prefix='$'
                          suffix={planData.lease.currency}
                        />
                      </InfoField>
                    </InfoGrid>

                    <InfoGrid>
                      <InfoField title='Reason' className='col-span-4'>
                        <TextAreaInput
                          placeholderText='Reason for Review'
                          value={values?.review?.reason}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='review.reason'
                          className='min-h-[200px]'
                        />
                      </InfoField>
                    </InfoGrid>
                  </>
                )}
              </InfoCard>

              {/* Viewable Fields for Reference */}
              <InfoCard title='Submitted Information'>
                {/* shared information for all claim types */}
                <InfoGrid>
                  <InfoField
                    title='Claim Number'
                    className='col-span-2 sm:col-span-1'
                  >
                    {claimData?.id}
                  </InfoField>
                  <InfoField
                    title='Plan Number'
                    className='col-span-2 sm:col-span-1'
                  >
                    {planData?.id}
                  </InfoField>
                  <InfoField title='Lease' className='col-span-2 sm:col-span-1'>
                    {planData?.lease?.address?.streetAddress1},{' '}
                    {planData?.lease?.address?.city}
                  </InfoField>
                  <InfoField title='Claim Type'>
                    {claimData?.claimType === claimTypeEnum.CANCEL_ANYTIME
                      ? 'Cancel Lease'
                      : 'Early Move Out'}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  <InfoField title='Earliest Move Out Date'>
                    {formatDateToHumanDate(claimData?.submission?.moveOutStart)}
                  </InfoField>
                  <InfoField title='Latest Move Out Date'>
                    {formatDateToHumanDate(claimData?.submission?.moveOutEnd)}
                  </InfoField>
                  <InfoField title='Payment Preference'>
                    {claimData?.submission?.paymentPreference ===
                    claimPaymentPreferenceEnum.DIRECT
                      ? 'Direct'
                      : 'Reimbursement'}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  <InfoField title='Reason for Move Out' className='col-span-4'>
                    {claimData?.submission?.moveReason}
                  </InfoField>
                </InfoGrid>
              </InfoCard>
            </>
          </DashboardWrapperLayout>
        );
      }}
    </Formik>
  );
};

export default ClaimEdit;
