import { act } from "react";

// ENUM VALUES
export const currency = {
  USD: '$',
};

export const propertyStatusEnum = {
  INACTIVE: 'Inactive',
  OPTOUT: 'Opt-Out',
  OPTIN: 'Opt-In',
  REQUIRED: 'Required',
};

export const invoiceStatusEnum = {
  DRAFT: 'draft',
  DELETED: 'deleted',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void',
};

export const propertyKindEnum = {
  MULTIFAMILY: 'Multifamily',
  STUDENT: 'Student',
  OTHER: 'Other',
};

export const planPaymentMode = {
  ONE_TIME: 'onetime',
  SUBSCRIPTION: 'subscription',
};

export const coverageTypes = {
  CANCEL_ANYTIME: 'cancelAnytime',
  LEAVE_ANYTIME: 'leaveAnytime',
};

export const planStatusEnum = {
  PENDING: 'pending',
  ACTIVE: 'active',
  COMPLETE: 'complete',
  REJECTED: 'rejected',
  ON_GOING_CLAIM: 'ongoingClaim',
  CANCELLED: 'cancelled',
  CREATED: 'created',
  REVIEW: 'review',
  CLOSED: 'closed',
  SUSPENDED: 'suspended',
};

export const statusEnum = {
  // PLANS
  pending: 'pending',
  active: 'active',
  complete: 'complete',
  rejected: 'rejected',
  cancelled: 'cancelled',
  created: 'created',
  review: 'review',
  closed: 'closed',
  suspended: 'suspended',
  ongoingClaim: 'ongoingClaim',

  // CLAIMS
  Draft: 'Draft',
  submitted: 'submitted',
  Completed: 'Completed',
  inReview: 'review',
  Expired: 'expired',
  Denied: 'denied',
  Approved: 'approved',
  Confirmed: 'confirmed',
};
export const claimTypeEnum = {
  NEW_JOB: 'newJob',
  PURCHASED_HOME: 'purchasedHome',
  OTHER_CLAIM: 'other',
};
export const claimStatusEnum = {
  DRAFT: 'draft',
  SUBMITTED: 'submitted',  
  IN_REVIEW: 'review',
  APPROVED: 'approved',
  RESOLVED: 'resolved',
  CONFIRMED: 'confirmed',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  DENIED: 'denied',
};

export const claimPaymentPreferenceEnum = {
  DIRECT: 'direct',
  REIMBURSEMENT: 'reimbursement',
};

export const ownerStatusEnum = {
  ACTIVE: 'Active',
  DELETED: 'Deleted',
};

export const ownerKindEnum = {
  OWNER: 'Owner',
  MANAGER: 'Manager',
};

export const stripeKYCEnum = {
  pending: 'pending',
  failed: 'error',
  verified: 'verified',
};
export const planPaymentType = {
  ONETIME: 1,
  SUBSCRIPTION: 2,
};
export const DOTS = '...';
export const PENDING = 'pending';
export const PENDING_PAYMENT = 'Pending Payment';
export const ACTIVE = 'Active';

export const MAX_DOCUMENT_SIZE = 20971520; // 20MB
export const MAX_PROFILE_IMAGE_SIZE = 5242880;

// INITIAL VALUES
export const uploadDocumentSchema = {
  path: '',
  name: '',
  size: 0,
  type: '',
  webkitRelativePath: '',
};
export const localeTimeOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const shortTime = new Intl.DateTimeFormat('en', {
  timeStyle: 'short',
});

export const settingsTab = {
  ACCOUNT: 1,
  PAYMENT: 2,
  VERIFICATION: 3,
};

export const iconPositionEnum = {
  LEFT: 1,
  RIGHT: 2,
};

export const POLLING_INTERVAL = 3000; //milliseconds

export const userTrackingEnum = {
  CONSOLE: 'console',
  SEGMENT: 'segment',
};

export const tenantStatusEnum = {
  NEW: 'New',
  INVITED: 'Invited',
  ACCEPTED: 'Accepted',
  DELETED: 'Deleted',
  VERIFIED: 'Verified',
  NOT_VERIFIED: 'Not Verified',
};

export const documentStatusEnum = {
  NONE: 'none',
  UPLOADED: 'uploaded',
  REJECTED: 'rejected',
  VERIFIED: 'verified',
};

export const planPaymentEnum = {
  ONETIME: 'onetime',
  MONTHLY: 'monthly',
};

export const verificationStatusEnum = {
  VERIFIED: true,
  NOT_VERIFIED: false,
};

export const paymentMethodEnum = {
  WIRE: 'wire',
  ACH: 'ach',
  ETRANSFER: 'etransfer',
  CHECK: 'check',
};

export const claimConfirmationTypeEnum = {
  VACATED: 'vacated',
  CURRENT: 'current',
  RELISTED: 'relisted',
};

export const rentalTypeEnum = {
  MULTIFAMILY: 'Multifamily',
  STUDENT_HOUSING: 'Student Housing',
  OTHER: 'Other',
};

export const statesEnum = {
  ALABAMA: 'AL',
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DISTRICT_OF_COLUMBIA: 'DC',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  NEW_HAMPSHIRE: 'NH',
  NEW_JERSEY: 'NJ',
  NEW_MEXICO: 'NM',
  NEW_YORK: 'NY',
  NORTH_CAROLINA: 'NC',
  NORTH_DAKOTA: 'ND',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  RHODE_ISLAND: 'RI',
  SOUTH_CAROLINA: 'SC',
  SOUTH_DAKOTA: 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  WEST_VIRGINIA: 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY',
};

export const bedroomEnum = {
  STUDIO: '0',
  ONE_BED: '1',
  TWO_BED: '2',
  THREE_BED: '3',
  FOUR_BED_PLUS: '4+',
};

export const propertyRatesStatusEnum = {
  APPROVED: 'approved',
  NOT_APPROVED: 'not-approved',
  PENDING: 'pending',
  EXPIRED: 'expired',
};

export const propertyBillingEnum = {
  BULK: 'bulk',
  INDIVIDUAL: 'individual',
};

export const planIncomeControlledEnum = {
  YES: "controlled",
  NO: "not-controlled",
};

export const dateRangeStatusEnum = {
  BEFORE: "upcoming",
  AFTER: "expired",
  WITHIN: "active",
}

export const studentStatusEnum = {
  NOT_STUDENT: 'not-student',
  CURRENT_STUDENT: 'current-student',
}
