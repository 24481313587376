import React from 'react';
import StatusLabelView from 'common/components/status-label';
import { propertyKindEnum } from 'common/utils/enums';

const PropertyType = ({ rentalType=propertyKindEnum.MULTIFAMILY }) => {

  // No separate color scheme for rental type right now

  return (
    <StatusLabelView
      status={rentalType}
    />
  );
};

export default PropertyType;
