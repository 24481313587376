import React from 'react';
import { propertyStatusEnum, propertyRatesStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'Inactive' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case propertyStatusEnum.INACTIVE:
      colorScheme = 'gray';
      break;
    case propertyStatusEnum.OPTIN:
      colorScheme = 'yellow';
      break;
    case propertyStatusEnum.OPTOUT:
      colorScheme = 'blue';
      break;
    case propertyStatusEnum.REQUIRED:
      colorScheme = 'green';
      break;
    case propertyRatesStatusEnum.PENDING:
      colorScheme = 'yellow';
      break;
    case propertyRatesStatusEnum.APPROVED:
      colorScheme = 'green';
      break;
    case propertyRatesStatusEnum.NOT_APPROVED:
      colorScheme = 'red';
      break;
    case propertyRatesStatusEnum.EXPIRED:
      colorScheme = 'gray';
      break;
    default:
      colorScheme = 'gray';
  }

  return <StatusLabelView status={status} colorScheme={colorScheme} />;
};

export { StatusLabel };
