// Utils for calculating specific numbers
import { safeNumberConversion } from 'common/utils/misc';


export const calculateAverageRate = (rates) => {
  // Get the unit counts for each bedroom type
  const studioUnits = safeNumberConversion(rates?.['0']?.numberOfUnits);
  const oneBedUnits = safeNumberConversion(rates?.['1']?.numberOfUnits);
  const twoBedUnits = safeNumberConversion(rates?.['2']?.numberOfUnits);
  const threeBedUnits = safeNumberConversion(rates?.['3']?.numberOfUnits);
  const fourBedPlusUnits = safeNumberConversion(rates?.['4+']?.numberOfUnits);
  const totalUnits = studioUnits + oneBedUnits + twoBedUnits + threeBedUnits + fourBedPlusUnits;

  // Get the calculated rates for each bedroom type
  let studioRate = 0;
  let oneBedRate = 0;
  let twoBedRate = 0;
  let threeBedRate = 0;
  let fourBedPlusRate = 0;
  if (rates) {
    studioRate = rates?.['0']?.rate
    oneBedRate = rates?.['1']?.rate
    twoBedRate = rates?.['2']?.rate
    threeBedRate = rates?.['3']?.rate
    fourBedPlusRate = rates?.['4+']?.rate
  }

  // Calculate the weighted average rate
  const weightedAverageRate = (
    (studioUnits * studioRate) +
    (oneBedUnits * oneBedRate) +
    (twoBedUnits * twoBedRate) +
    (threeBedUnits * threeBedRate) +
    (fourBedPlusUnits * fourBedPlusRate)
  ) / totalUnits;

  return weightedAverageRate;
}