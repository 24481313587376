import EmptyPage from 'common/components/empty-page';
import ClaimsTable from 'components/claims/claims-table';
import { useEffect } from 'react';

const { default: Toggle } = require('common/components/toggle');
const {
  default: DashboardWrapperLayout,
} = require('layouts/dashboard-wrapper');
const { useNavigate, useParams } = require('react-router-dom');
const { useGetPlanQuery } = require('redux/api/ciosAdminApi/ciosAdminApi');

const PlanClaims = () => {
  const navigate = useNavigate();
  const { planId } = useParams();

  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
  } = useGetPlanQuery(planId);

  const { data: planData } = planQuery || {
    data: {},
  };

  let claims = planData.claims || [];

  // Set up additional toggle for claims
  const toggles = [
    {
      text: 'View Claims',
      path: `/dashboard/plans/${planId}/claims`,
    },
  ];

  useEffect(() => {
    if (planData) {
      console.log('Plan Data:', planData);
    }
  }
  , [planData
  ]);

  return (
    <DashboardWrapperLayout
      title='Plan Claims'
      buttons={
        <Toggle
          selected={'View Claims'}
          editPath={`/dashboard/plans/${planId}/edit`}
          viewPath={`/dashboard/plans/${planId}`}
          newPaths={toggles}
        />
      }
    >
      {isGetError ? (
        <EmptyPage
          isError={isGetError}
          title='Error Loading Plan'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      ) : isGetLoading ? (
        <EmptyPage
          title='Loading Plan'
          subtitle='Please wait while we load your plan'
          isLoading={isGetLoading}
        />
      ) : !planData ? (
        <EmptyPage
          title='No Plan Found'
          subtitle='No plan found. Try adding a new plan.'
          buttonText={'Add Plan'}
          onClick={() => navigate(`/dashboard/plans/new`)}
        />
      ) : (
        <>
          {claims.length > 0 ? (
            <ClaimsTable claims={claims} planView/>
          ) : (
            <EmptyPage
              title='No Claims Found'
              subtitle='No claims found for this plan.'
            />
          )}
        </>
      )}
    </DashboardWrapperLayout>
  );
};

export default PlanClaims;
