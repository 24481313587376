import { useState } from 'react';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDeletePropertyMutation } from 'redux/api/ciosAdminApi/ciosAdminApi';
import {
  BodyRow,
  HeaderCell,
  HeaderRow,
  Table,
  TableBody,
  TableCell,
} from 'common/components/data-table';
import SortButton from 'common/components/sort-button';
import { formatPhoneNumber } from 'react-phone-number-input';
import { StatusLabel } from 'components/properties/status-label';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { HamburgerIcon, ViewIcon } from '@chakra-ui/icons';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa6';
import { BsFileEarmarkCheck, BsFileEarmarkPlus, BsFileEarmarkPlusFill, BsFileEarmarkSpreadsheet, BsFileEarmarkSpreadsheetFill, BsFileEarmarkX } from 'react-icons/bs';

const PropertiesTable = ({
  properties,
  sortVal,
  setSort,
  isRounded = true,
}) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const [
    deleteProperty,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeletePropertyMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  const gridLayout = 'grid-cols-5 mobile:grid-cols-2';

  return (
    <Table>
      {deleteModal.open && (
        <ConfirmActionModal
          action={`delete this property`}          
          onConfirmClick={() => {
            deleteProperty({ id: deleteModal.id })
              .unwrap()
              .then(() => {
                setDeleteModal({ open: false, id: null });
                navigate('/dashboard/properties');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModal({ open: false, id: null });
          }}
        />
      )}

      {/* head */}
      <HeaderRow
        isRounded={isRounded}
        gridLayout={gridLayout}
      >
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='name'
            label='Property Name'
          />
        </HeaderCell>
        <HeaderCell className='mobile:hidden'>Owner</HeaderCell>
        <HeaderCell className='mobile:hidden'>Contact Info</HeaderCell>
        <HeaderCell className='mobile:hidden'>Address</HeaderCell>        
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='status'
            label='Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {properties?.map((property, index) => (
          <BodyRow
            gridLayout={gridLayout}
            key={index}
            onClick={() => {
              navigate(`/dashboard/properties/${property.id}`);
            }}
          >
            {/* Name and ID */}
            <TableCell
              top={property?.name}
              bottom={property?.id}              
            />

            {/* Owner */}
            <TableCell
              top={property?.owner?.name || '-'}
              bottom={property?.owner?.id || '-'}
              className='mobile:hidden'
            />

            {/* Contact Info (Email + Phone) */}
            <TableCell
              top={property?.contactName || 'No Contact Name'}
              bottom={property?.email}
              bottomLink={`mailto:${property?.email}`}
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
              className='mobile:hidden'
            />

            {/* Address */}
            {/* Placeholder when address is undefined (shouldn't happen) */}
            {property?.address?.streetAddress1 ? (
              <TableCell
                top={`${property?.address.streetAddress1}${
                  property?.address?.streetAddress2 ? ', ' : ''
                }${property?.address?.streetAddress2 || ''}`}
                bottom={`${property?.address.city}, ${
                  property?.address.state || ''
                } ${property?.address.postalCode || ''}`}
                className='mobile:hidden'
              />
            ) : (
              <TableCell top='-' bottom='-' className='mobile:hidden'/>
            )}            

            {/* Status */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel status={property?.status} size='sm' />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='ghost'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/properties/${property?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Property Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/properties/${property?.id}/edit`);
                    }}
                    icon={<BiEdit />}
                  >
                    Edit Property Details
                  </MenuItem>
                  {property?.rates?.underwriting?.rentalUnitBreakdown ? (
                    <>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/dashboard/properties/${property?.id}/rates`);
                        }}
                        icon={<BsFileEarmarkSpreadsheet />}
                      >
                        View Rates
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/dashboard/properties/${property?.id}/rates/edit`);
                        }}
                        icon={<BsFileEarmarkCheck />}
                      >
                        Change Rates
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/properties/${property?.id}/rates/edit`);
                      }}
                      icon={<BsFileEarmarkPlus />}
                    >
                      Add Rates
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/plans/new?propertyId=${property?.id}`);
                    }}
                    icon={<FaPlus />}
                  >
                    Add Plan to Property
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModal({ open: true, id: property?.id });
                    }}
                    icon={<AiOutlineDelete />}
                  >
                    Delete Property
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PropertiesTable;
