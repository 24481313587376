import { useParams } from 'react-router-dom';
import Button from 'common/components/button';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { useGetPropertyQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Toggle from 'common/components/toggle';
import { InfoCard, InfoGridView } from 'common/components/info-card';
import { StatusLabel } from 'components/properties/status-label';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import DocumentPreview from 'common/components/document-preview';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { RateSummary } from 'components/properties/rate-summary';
import { formatAsPercentage } from 'common/utils/misc';

const PropertyRatesView = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();

  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetPropertyQuery(propertyId);

  // Configure all the infofields
  const propertyRate = propertyData?.rates;

  const propertyInformation = [
    {
      condition: propertyRate?.status,
      title: 'Status',
      value: <StatusLabel status={propertyRate?.status} />,
    },
    {
      condition: propertyRate?.partnerTermSheet,
      title: 'Partner Term Sheet',
      value: (
        <DocumentPreview
          label={`${propertyData?.name} Term Sheet`}
          url={propertyRate?.partnerTermSheet}
        />
      ),
    },
    {
      condition: propertyRate?.underwriting?.date,
      title: 'Underwriting Date',
      value: formatDateToHumanDate(propertyRate?.underwriting?.date),
    },
  ];

  const propertyUnderwriting = [
    {
      condition: propertyRate?.underwriting?.defaultLeaseBuyout > 0,
      title: 'Fixed Buyout Months',
      value: `${propertyRate?.underwriting?.defaultLeaseBuyout} months`,
    },
    {
      condition: propertyRate?.underwriting?.propertyCommissionRate > 0,
      title: 'Property Commission Rate',
      value: `${formatAsPercentage(propertyRate?.underwriting?.propertyCommissionRate)}%`,
    },
    {
      condition:
        propertyRate?.underwriting?.trailing12MonthHistorical?.monthsOnMarket,
      title: 'TTM Months on Market',
      value: `${propertyRate?.underwriting?.trailing12MonthHistorical?.monthsOnMarket} months`,
    },
    {
      condition:
        propertyRate?.underwriting?.trailing12MonthHistorical
          ?.earlyTerminationRate,
      title: 'TTM Early Termination Rate',
      value: `${formatAsPercentage(propertyRate?.underwriting?.trailing12MonthHistorical?.earlyTerminationRate)}%`,
    },
  ];

  // Configure the rate by layout fields
  const unitRates = propertyRate?.underwriting?.rentalUnitBreakdown;
  const studioRate = [
    {
      condition: unitRates?.['0']?.numberOfUnits > 0,
      title: 'Studio (0bdr) Units',
      value: `${unitRates?.['0']?.numberOfUnits} units`,
    },
    {
      condition: unitRates?.['0']?.averageRent > 0,
      title: 'Studio (0bdr) Average Rent',
      value: FormatAsCurrency(unitRates?.['0']?.averageRent),
    },
    {
      condition: unitRates?.['0']?.rate > 0,
      title: 'Studio (0bdr) Rate',
      value: `${formatAsPercentage(unitRates?.['0']?.rate)}%`,
    },
    {
      condition: unitRates?.['0']?.rate > 0 && unitRates?.['0']?.averageRent > 0,
      title: 'Average Monthly Price Per Unit',
      value: FormatAsCurrency(unitRates?.['0']?.averageRent * unitRates?.['0']?.rate, 'USD'),
    }
  ];

  const oneBedRate = [
    {
      condition: unitRates?.['1']?.numberOfUnits > 0,
      title: '1 Bedroom Units',
      value: `${unitRates?.['1']?.numberOfUnits} units`,
    },
    {
      condition: unitRates?.['1']?.averageRent > 0,
      title: '1 Bedroom Average Rent',
      value: FormatAsCurrency(unitRates?.['1']?.averageRent),
    },
    {
      condition: unitRates?.['1']?.rate > 0,
      title: '1 Bedroom Rate',
      value: `${formatAsPercentage(unitRates?.['1']?.rate)}%`,
    },
    {
      condition: unitRates?.['1']?.rate > 0 && unitRates?.['1']?.averageRent > 0,
      title: 'Average Monthly Price Per Unit',
      value: FormatAsCurrency(unitRates?.['1']?.averageRent * unitRates?.['1']?.rate, 'USD'),
    }
  ];

  const twoBedRate = [
    {
      condition: unitRates?.['2']?.numberOfUnits > 0,
      title: '2 Bedroom Units',
      value: `${unitRates?.['2']?.numberOfUnits} units`,
    },
    {
      condition: unitRates?.['2']?.averageRent > 0,
      title: '2 Bedroom Average Rent',
      value: FormatAsCurrency(unitRates?.['2']?.averageRent),
    },
    {
      condition: unitRates?.['2']?.rate > 0,
      title: '2 Bedroom Rate',
      value: `${formatAsPercentage(unitRates?.['2']?.rate)}%`,
    },
    {
      condition: unitRates?.['2']?.rate > 0 && unitRates?.['2']?.averageRent > 0,
      title: 'Average Monthly Price Per Unit',
      value: FormatAsCurrency(unitRates?.['2']?.averageRent * unitRates?.['2']?.rate, 'USD'),}
  ];

  const threeBedRate = [
    {
      condition: unitRates?.['3']?.numberOfUnits > 0,
      title: '3 Bedroom Units',
      value: `${unitRates?.['3']?.numberOfUnits} units`,
    },
    {
      condition: unitRates?.['3']?.averageRent > 0,
      title: '3 Bedroom Average Rent',
      value: FormatAsCurrency(unitRates?.['3']?.averageRent),
    },
    {
      condition: unitRates?.['3']?.rate > 0,
      title: '3 Bedroom Rate',
      value: `${formatAsPercentage(unitRates?.['3']?.rate)}%`,
    },
    {
      condition: unitRates?.['3']?.rate > 0 && unitRates?.['3']?.averageRent > 0,
      title: 'Average Monthly Price Per Unit',
      value: FormatAsCurrency(unitRates?.['3']?.averageRent * unitRates?.['3']?.rate, 'USD'),
    }
  ];

  const fourBedPlusRate = [
    {
      condition: unitRates?.['4+']?.numberOfUnits > 0,
      title: '4+ Bedroom Units',
      value: `${unitRates?.['4+']?.numberOfUnits} units`,
    },
    {
      condition: unitRates?.['4+']?.averageRent > 0,
      title: '4+ Bedroom Average Rent',
      value: FormatAsCurrency(unitRates?.['4+']?.averageRent),
    },
    {
      condition: unitRates?.['4+']?.rate > 0,
      title: '4+ Bedroom Rate',
      value: `${formatAsPercentage(unitRates?.['4+']?.rate)}%`,
    },
    {
      condition: unitRates?.['4+']?.rate > 0 && unitRates?.['4+']?.averageRent > 0,
      title: 'Average Monthly Price Per Unit',
      value: FormatAsCurrency(unitRates?.['4+']?.averageRent * unitRates?.['4+']?.rate, 'USD'),
    }
  ];

  return (
    <DashboardWrapperLayout
      title={isGetLoading ? 'Loading Rate Details' : `${propertyData?.name} Rate Details`}
      buttons={
        <Toggle
          selected={'view'}
          editText='Edit Rates'
          editPath={`/dashboard/properties/${propertyData?.id}/rates/edit`}
          viewText='View Rates'
          viewPath={`/dashboard/properties/${propertyData?.id}/rates`}
        />
      }
    >
      {isGetError ? (
        <EmptyPage
          title='Error Loading Rate'
          subtitle='There was an error loading this rate. Please try again.'
          buttonText='Reload Page'
          onClick={() => {
            window.location.reload();
          }}
          isError
        />
      ) : isGetLoading ? (
        <EmptyPage
          title='Loading Rate Details'
          subtitle='Please wait while we load rate details.'
          isLoading
        />
      ) : !propertyData ? (
        <EmptyPage
          title='No Rate Found'
          subtitle='This rate does not exist.'
          buttonText={'Go Back'}
          onClick={() => {
            navigate(`/dashboard/properties/${propertyData?.id}`);
          }}
        />
      ) : (
        <>
          {/* Action buttons */}
          <FloatingButtons>
            <Button
              onClick={() => {
                navigate(
                  `/dashboard/properties/${propertyData?.id}/rates/edit`
                );
              }}
              title='Change Rates'
              wFull={false}
            />
            <Button
              onClick={() => {
                navigate(`/dashboard/properties/${propertyData?.id}`);
              }}
              title='Back to Property'
              wFull={false}
              theme='secondary'
            />
          </FloatingButtons>

          <RateSummary
            values={propertyData}
            finalRates={propertyData?.rates?.underwriting?.rentalUnitBreakdown}
            sticky={false}
          />

          {/* Property Details Table */}
          <InfoCard title='Property Information'>
            <InfoGridView infoFields={propertyInformation} />
            <InfoGridView infoFields={propertyUnderwriting} />
          </InfoCard>

          {/* Rates by Unit Type */}
          <InfoCard title='Rates by Unit Type'>
            {unitRates?.['0']?.numberOfUnits > 0 && (
              <InfoGridView infoFields={studioRate} />
            )}
            {unitRates?.['1']?.numberOfUnits > 0 && (
              <InfoGridView infoFields={oneBedRate} />
            )}
            {unitRates?.['2']?.numberOfUnits > 0 && (
              <InfoGridView infoFields={twoBedRate} />
            )}
            {unitRates?.['3']?.numberOfUnits > 0 && (
              <InfoGridView infoFields={threeBedRate} />
            )}
            {unitRates?.['4+']?.numberOfUnits > 0 && (
              <InfoGridView infoFields={fourBedPlusRate} />
            )}
          </InfoCard>
        </>
      )}
    </DashboardWrapperLayout>
  );
};

export default PropertyRatesView;
