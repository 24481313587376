import { useState, useEffect } from 'react';
import Toggle from 'common/components/toggle';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import StatusLabel from 'components/plans/status-label';
import { StatusLabel as MemberStatusLabel } from 'components/members/status-label';
import { StatusLabel as DocumentStatusLabel } from 'components/documents/status-label';
import { StatusLabel as VerificationStatusLabel } from 'components/verification/status-label';
import { StatusLabel as PropertyStatusLabel } from 'components/properties/status-label';
import DocumentPreview from 'common/components/document-preview';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useClaimAccountTenantReqMutation,
  useGetPlanQuery,
  useImpersonateTenantMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import Button from 'common/components/button';
import {
  InfoCard,
  InfoGrid,
  InfoField,
  InfoGridView,
} from 'common/components/info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { formatPhoneNumber } from 'react-phone-number-input';
import PropertyType from 'common/components/property-type';
import { residencyStatusOptions } from 'common/utils/selectOptions';
import { ProgressBar, ProgressBarStep } from 'common/components/progress-bar';
import {
  bedroomEnum,
  planStatusEnum,
  studentStatusEnum,
} from 'common/utils/enums';
import { extractDocumentName } from 'common/utils/extractDocumentName';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import CopyLink from 'common/components/copy-link';
import {
  FaBuildingColumns,
  FaFileContract,
  FaHouseChimneyUser,
  FaHouseUser,
} from 'react-icons/fa6';
import ChangeStatusModal from 'components/plans/change-status-modal';
import { formatAsPercentage, toTitleCase } from 'common/utils/misc';

function ClaimAccountArea({ userId, planId }) {
  const [claimAccount, { isLoading }] = useClaimAccountTenantReqMutation();
  const [claimUrl, setClaimUrl] = useState('');
  return (
    <>
      <Button
        title='Send Activation Email'
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId, planID: planId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
              toast.success('Activation link sent to member');
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
        wFull={false}
        className='px-6'
      />
      {claimUrl && <CopyLink text={claimUrl} title='activation link' />}
    </>
  );
}

const PlanView = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const [statusModal, setStatusModal] = useState({
    open: false,
    id: null,
  });
  const [impersonateTenant, { isLoading: isImpersonateLoading }] =
    useImpersonateTenantMutation();

  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
  } = useGetPlanQuery(planId);

  const { data: planData } = planQuery || {
    data: {},
  };

  const propertyData = planData?.property;
  const hasCancelAnytime =
    planData?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    planData?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  // Set up field grids
  const contactInfoFields = [
    {
      condition: propertyData?.email,
      title: 'Contact Email',
      value: propertyData?.email,
    },
    {
      condition: propertyData?.phone,
      title: 'Contact Phone',
      value: formatPhoneNumber(propertyData?.phone),
    },
    {
      condition: propertyData?.website,
      title: 'Website',
      value: propertyData?.website,
    },
  ];

  const addressInfoFields = [
    {
      condition: propertyData?.address?.streetAddress1,
      title: 'Street Address 1',
      value: propertyData?.address?.streetAddress1,
    },
    {
      condition: propertyData?.address?.streetAddress2,
      title: 'Street Address 2',
      value: propertyData?.address?.streetAddress2,
    },
    {
      condition: propertyData?.address?.city,
      title: 'City',
      value: propertyData?.address?.city,
    },
    {
      condition: propertyData?.address?.state,
      title: 'State',
      value: propertyData?.address?.state,
    },
    {
      condition: propertyData?.address?.postalCode,
      title: 'Postal Code',
      value: propertyData?.address?.postalCode,
    },
    {
      condition: propertyData?.address?.country,
      title: 'Country',
      value: propertyData?.address?.country,
    },
  ];

  const leaseTerminationInfoFields = [
    {
      condition: !!planData?.lease?.terminationPolicy?.noticePeriod, // Show only if noticePeriod exists
      title: 'Termination Notice Period',
      value: `${planData?.lease?.terminationPolicy?.noticePeriod} Days`,
    },
    {
      condition: !!planData?.lease?.terminationPolicy?.penalty, // Show only if penalty exists
      title: 'Termination Penalty',
      value: `${FormatAsCurrency(
        planData?.lease?.terminationPolicy?.penalty,
        planData?.lease?.currency
      )} ${planData?.lease?.currency}`,
    },
    {
      condition: !!planData?.lease?.terminationPolicy?.buyoutMonths, // Show only if buyoutMonths exists
      title: 'Lease Buyout Months',
      value: `${planData?.lease?.terminationPolicy?.buyoutMonths} months`,
    },
    {
      condition: !!planData?.lease?.terminationPolicy?.buyout, // Show only if buyout exists
      title: 'Lease Buyout Amount',
      value: `${FormatAsCurrency(
        planData?.lease?.terminationPolicy?.buyout,
        planData?.lease?.currency
      )} ${planData?.lease?.currency}`,
    },
  ];

  // For Testing
  // useEffect(() => {
  //   if (planData) {
  //     console.log(planData);
  //   }
  // }, [planData]);

  return (
    <DashboardWrapperLayout
      title={`Plan Details`}
      buttons={
        <Toggle
          selected={'view'}
          editPath={`/dashboard/plans/${planId}/edit`}
          viewPath={`/dashboard/plans/${planId}`}
          newPaths={[
            {
              text: "View Claims",
              path: `/dashboard/plans/${planId}/claims`,
            }
          ]}
        />
      }
    >
      {isGetError ? (
        <EmptyPage
          isError={isGetError}
          title='Error Loading Plan'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      ) : isGetLoading ? (
        <EmptyPage
          title='Loading Plan'
          subtitle='Please wait while we load your plan'
          isLoading={isGetLoading}
        />
      ) : !planData ? (
        <EmptyPage
          title='No Plan Found'
          subtitle='No plan found. Try adding a new plan.'
          buttonText={'Add Plan'}
          onClick={() => navigate(`/dashboard/plans/new`)}
        />
      ) : (
        <>
          {/* Action buttons */}
          <FloatingButtons>
            {/* Change Status Button */}
            <Button
              onClick={() => {
                console.log('Opening status modal for plan:', planId);
                setStatusModal({ open: true, id: planId });
              }}
              title='Change Status'
              wFull={false}
            />

            {/* Show account activation conditionally based on the email confirmation status */}
            {!planData.user?.emailConfirmed && planData.user?.id && (
              <ClaimAccountArea userId={planData.user.id} />
            )}
            <Button
              onClick={() => {
                navigate(`/dashboard/members/${planData.user.id}`);
              }}
              title='View Member Details'
              wFull={false}
              className='px-6'
              theme='secondary'
            />

            {/* Show button to property details if set */}
            {planData?.property && (
              <Button
                onClick={() => {
                  navigate(`/dashboard/properties/${planData?.property?.id}`);
                }}
                title='View Property Details'
                wFull={false}
                className='px-6'
                theme='secondary'
              />
            )}

            {/* Impersonate User Button */}
            <Button
              disabled={isImpersonateLoading}
              onClick={() => {
                impersonateTenant({ id: planData.user.id })
                  .unwrap()
                  .then(({ token }) => {
                    let url = `${process.env.REACT_APP_IMPERSONATION_URL}?token=${token}&planId=${planData.id}`;
                    window.open(url, '_blank');
                  });
                /* location.href = */
                /* navigate(`/dashboard/plans/new?userID=${tenantData.id}`); */
              }}
              theme='secondary'
              title='Log In As User'
              wFull={false}
              className='px-6'
            />
          </FloatingButtons>

          {/* Change Status Modal */}
          {statusModal.open ? (
            <ChangeStatusModal
              onCancel={() => setStatusModal({ open: false, id: null })}
              planId={statusModal.id}
            />
          ) : null}

          {/* Progress Bar */}
          {[
            planStatusEnum.PENDING,
            planStatusEnum.CREATED,
            planStatusEnum.REVIEW,
          ].includes(planData?.status) ? (
            <div className='flex flex-col gap-3'>
              <h2 className='font-medium text-lg'>Plan Application Progress</h2>
              <ProgressBar>
                <ProgressBarStep
                  title='Property Selected'
                  complete={planData?.property}
                />
                <ProgressBarStep
                  title='Member Added'
                  complete={planData?.user}
                />
                <ProgressBarStep
                  title='Lease Added'
                  complete={planData?.lease}
                />
                <ProgressBarStep
                  title='Coverage Added'
                  complete={hasCoverage}
                />
                <ProgressBarStep
                  title='Ready for Review'
                  complete={planData?.status === planStatusEnum.REVIEW}
                />
              </ProgressBar>
            </div>
          ) : null}

          {/* Plan Information Table */}
          <InfoCard title='Plan Information'>
            <InfoGrid>
              <InfoField title='Plan Number'>
                <CopyLink text={planId} title='Plan Number' />
              </InfoField>
              <InfoField title='Plan Status'>
                <StatusLabel status={planData?.status} />
              </InfoField>
              <InfoField title='Created On'>
                {formatDateToHumanDate(planData?.createdAt)}
              </InfoField>
            </InfoGrid>
          </InfoCard>

          {/* Property Details Table */}
          {planData?.property && (
            <InfoCard title='Property Information'>
              <InfoGrid>
                <InfoField title='Name'>{propertyData?.name}</InfoField>
                <InfoField title='Property Number'>
                  <CopyLink text={propertyData?.id} title='Property Number' />
                </InfoField>
                <InfoField title='Status'>
                  <PropertyStatusLabel status={propertyData?.status} />
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Property Type'>
                  <PropertyType type={propertyData?.rentalType} />
                </InfoField>
                <InfoField title='Billing Preference'>
                  <PropertyStatusLabel status={propertyData?.billing} />
                </InfoField>
              </InfoGrid>
              <InfoGridView infoFields={contactInfoFields} />
              <InfoGridView infoFields={addressInfoFields} />
              {propertyData?.notes ? (
                <InfoGrid>
                  <InfoField title='Notes' className='col-span-4'>
                    {propertyData?.notes}
                  </InfoField>
                </InfoGrid>
              ) : null}
            </InfoCard>
          )}

          {/* Member Details Table */}
          {planData?.user && (
            <InfoCard title='Member Information'>
              <InfoGrid>
                <InfoField title='Name'>
                  {planData?.user?.name
                    ? planData?.user?.name
                    : `${planData?.user?.firstName} ${planData?.user?.lastName}`}
                </InfoField>
                <InfoField title='Phone'>
                  {formatPhoneNumber(planData?.user?.phone)}
                </InfoField>
                <InfoField title='Email' className='col-span-2'>
                  {planData?.user?.email}
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Member Status'>
                  <MemberStatusLabel
                    status={planData?.user?.status}
                    size='base'
                  />
                </InfoField>
                <InfoField title='ID Verification'>
                  <DocumentStatusLabel
                    status={planData?.user?.idVerification}
                    size='base'
                  />
                </InfoField>
                <InfoField title='Email Verification'>
                  <VerificationStatusLabel
                    status={planData?.user?.emailConfirmed}
                    size='base'
                  />
                </InfoField>
                <InfoField title='Phone Verification'>
                  <VerificationStatusLabel
                    status={planData?.user?.phoneConfirmed}
                    size='base'
                  />
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Job Quit Premeditated'>
                  <StatusLabel status={planData?.jobQuitPlans ? 'Yes' : 'No'} />
                </InfoField>
                <InfoField title='Moving Premeditated'>
                  <StatusLabel status={planData?.movePlans ? 'Yes' : 'No'} />
                </InfoField>
                <InfoField title='Residency Status'>
                  {
                    residencyStatusOptions.find(
                      (status) => status.value === planData?.residencyStatus
                    )?.label
                  }
                </InfoField>
                {planData?.residencyStatus !== 'citizenPermanent' && (
                  <InfoField title='Visa Expiration Date'>
                    {formatDateToHumanDate(planData?.user?.visaExpiration)}
                  </InfoField>
                )}
              </InfoGrid>
            </InfoCard>
          )}

          {/* Rental History */}
          {planData?.rentalHistory ? (
            <InfoCard title='Past 3 Years Rental History'>
              <InfoGrid>
                <InfoField title='Addressed Lived'>
                  {planData?.rentalHistory?.addressesLived} addresses
                </InfoField>
                <InfoField title='Termination History'>
                  {planData?.rentalHistory?.terminationHistory
                    ? '> 2 early terminations'
                    : '< 2 early terminations'}
                </InfoField>
                <InfoField title='Eviction History'>
                  {planData?.rentalHistory?.evictionHistory
                    ? '> 1 eviction'
                    : 'No evictions'}
                </InfoField>
              </InfoGrid>
            </InfoCard>
          ) : (
            <EmptyPage
              PageIcon={FaHouseChimneyUser}
              title='No Rental History Submitted'
              subtitle='No rental history submitted by member'
            />
          )}

          {/* School Information */}
          {planData?.school ? (
            <InfoCard title='School Information'>
              <InfoGrid>
                <InfoField title='School Status'>
                  <StatusLabel
                    status={toTitleCase(planData?.school?.status)}
                  />
                </InfoField>
                {planData?.school?.status ===
                studentStatusEnum.CURRENT_STUDENT ? (
                  <>
                    <InfoField title='Graduation Date'>
                      {formatDateToHumanDate(
                        planData?.school?.graduationDate
                      )}
                    </InfoField>
                    <InfoField title='Program End Date'>
                      {formatDateToHumanDate(
                        planData?.school?.programEndDate
                      )}
                    </InfoField>
                  </>
                ) : null}
              </InfoGrid>
            </InfoCard>
          ) : (
            <EmptyPage
              PageIcon={FaBuildingColumns}
              title='No School Information Submitted'
              subtitle='No school information submitted by member'
            />
          )}

          {/* Lease Information */}
          {planData?.lease ? (
            <InfoCard title='Lease Information'>
              <InfoGrid>
                <InfoField title='Street Address 1' className='col-span-2'>
                  {planData?.lease?.address?.streetAddress1}
                </InfoField>
                {planData?.lease?.address?.streetAddress2 && (
                  <InfoField title='Street Address 2' className='col-span-2'>
                    {planData?.lease?.address?.streetAddress2}
                  </InfoField>
                )}
                <InfoField title='City'>
                  {planData?.lease?.address?.city}
                </InfoField>
                {planData?.lease?.address?.state && (
                  <InfoField title='State'>
                    {planData?.lease?.address?.state}
                  </InfoField>
                )}
                <InfoField title='Postal Code'>
                  {planData?.lease?.address?.postalCode}
                </InfoField>
                <InfoField title='Country'>
                  {planData?.lease?.address?.country}
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Signing Date'>
                  {formatDateToHumanDate(planData?.lease?.signDate)}
                </InfoField>
                <InfoField title='Start Date'>
                  {formatDateToHumanDate(planData?.lease?.startDate)}
                </InfoField>
                <InfoField title='End Date'>
                  {formatDateToHumanDate(planData?.lease?.endDate)}
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Landlord Name'>
                  {planData?.lease?.landlordName}
                </InfoField>
                {planData?.lease?.monthlyRent ? (
                  <InfoField title='Monthly Rent'>
                    {FormatAsCurrency(
                      planData?.lease?.monthlyRent,
                      planData?.lease?.currency
                    )}{' '}
                    {planData?.lease?.currency}
                  </InfoField>
                ) : null}
                <InfoField title='Unit Layout'>
                  {planData?.lease?.numBeds == bedroomEnum.STUDIO
                    ? 'Studio'
                    : `${planData?.lease?.numBeds} Bedroom`}
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Furnished?'>
                  {planData?.lease?.furnished ? 'Furnished' : 'Unfurnished'}
                </InfoField>
                <InfoField title='Income Controlled'>
                  {toTitleCase(planData?.lease?.incomeControlled)}
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Repayable Concessions'>
                  {planData?.lease?.concessions?.repayable ? 'Yes' : 'No'}
                </InfoField>
                {planData?.lease?.concessions?.repayable ? (
                  <>
                    <InfoField title='Concession Discount'>
                      {formatAsPercentage(
                        planData?.lease?.concessions?.discount
                      )}
                      % Off Rent
                    </InfoField>
                    <InfoField title='Concession Length'>
                      {planData?.lease?.concessions?.months} Months
                    </InfoField>
                  </>
                ) : null}
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Lease Verification'>
                  <StatusLabel status={planData?.leaseVerification} />
                </InfoField>
                {planData?.lease?.document ? (
                  <InfoField title='Uploaded Lease' className='col-span-2'>
                    <DocumentPreview
                      label={extractDocumentName(planData?.lease?.document)}
                      url={planData?.lease?.document}
                    />
                  </InfoField>
                ) : null}
              </InfoGrid>
              <InfoGridView infoFields={leaseTerminationInfoFields} />
            </InfoCard>
          ) : (
            <EmptyPage
              PageIcon={FaFileContract}
              title='No Lease Information Submitted'
              subtitle='No lease information submitted by member'
            />
          )}

          {/* Coverage Information */}
          {hasCoverage ? (
            <InfoCard title='Coverage Information'>
              {hasCancelAnytime ? (
                <>
                  <InfoGrid>
                    <InfoField title='Coverage'>Cancel Anytime</InfoField>
                    <InfoField title='Coverage Start Date'>
                      {formatDateToHumanDate(
                        planData?.coverage?.cancelAnytime?.startDate
                      )}
                    </InfoField>
                    <InfoField title='Coverage End Date' className='col-span-2'>
                      {formatDateToHumanDate(
                        planData?.coverage?.cancelAnytime?.endDate
                      )}
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Payment Type'>Onetime</InfoField>
                    <InfoField title='Rate'>
                      {formatAsPercentage(
                        planData?.coverage?.cancelAnytime?.payment
                          ?.rentPercentage
                      )}
                      %
                    </InfoField>
                    <InfoField title='Term'>
                      {planData?.coverage?.cancelAnytime?.term} Months
                    </InfoField>
                    <InfoField title='Amount'>
                      {FormatAsCurrency(
                        planData?.coverage?.cancelAnytime?.payment?.amount,
                        planData?.lease?.currency
                      )}
                    </InfoField>
                    <InfoField
                      title='Stripe Link'
                      className='col-span-2'
                      onClick={() => {
                        let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${planData?.coverage?.cancelAnytime?.payment?.stripe?.id}`;
                        window.open(url, '_blank');
                      }}
                    >
                      {planData?.coverage?.cancelAnytime?.payment?.stripe?.id}
                    </InfoField>
                  </InfoGrid>
                </>
              ) : null}
              {hasLeaveAnytime ? (
                <>
                  <InfoGrid>
                    <InfoField title='Coverage'>Leave Anytime</InfoField>
                    <InfoField title='Coverage Start Date'>
                      {formatDateToHumanDate(
                        planData?.coverage?.leaveAnytime?.startDate
                      )}
                    </InfoField>
                    <InfoField title='Coverage End Date' className='col-span-2'>
                      {formatDateToHumanDate(
                        planData?.coverage?.leaveAnytime?.endDate
                      )}
                    </InfoField>
                  </InfoGrid>
                  {planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe
                    ?.id ? (
                    <InfoGrid>
                      <InfoField title='Payment Type'>Onetime</InfoField>
                      <InfoField title='Rate'>
                        {formatAsPercentage(
                          planData?.coverage?.leaveAnytime?.payment?.onetime
                            ?.rentPercentage
                        )}
                        %
                      </InfoField>
                      <InfoField title='Upfront Discount Rate'>
                        {formatAsPercentage(
                          planData?.coverage?.leaveAnytime?.payment?.onetime
                            ?.discountPercentage
                        )}
                        %
                      </InfoField>
                      <InfoField title='Amount'>
                        {FormatAsCurrency(
                          planData?.coverage?.leaveAnytime?.payment?.onetime
                            ?.amount,
                          planData?.lease?.currency
                        )}
                      </InfoField>
                      <InfoField
                        title='Stripe Link'
                        className='col-span-2'
                        onClick={() => {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id}`;
                          window.open(url, '_blank');
                        }}
                      >
                        {
                          planData?.coverage?.leaveAnytime?.payment?.onetime
                            ?.stripe?.id
                        }
                      </InfoField>
                    </InfoGrid>
                  ) : (
                    <InfoGrid>
                      <InfoField title='Payment Type'>Subscription</InfoField>
                      <InfoField title='Rate'>
                        {formatAsPercentage(
                          planData?.coverage?.leaveAnytime?.payment
                            ?.subscription?.rentPercentage
                        )}
                        %
                      </InfoField>
                      <InfoField title='Monthly Amount'>
                        {FormatAsCurrency(
                          planData?.coverage?.leaveAnytime?.payment
                            ?.subscription?.amount,
                          planData?.lease?.currency
                        )}{' '}
                        /month
                      </InfoField>
                      <InfoField
                        title='Stripe Link'
                        className='col-span-2'
                        onClick={() => {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}subscriptions/${planData?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id}`;
                          window.open(url, '_blank');
                        }}
                      >
                        {
                          planData?.coverage?.leaveAnytime?.payment
                            ?.subscription?.stripe?.id
                        }
                      </InfoField>
                    </InfoGrid>
                  )}
                </>
              ) : null}
            </InfoCard>
          ) : (
            <EmptyPage
              PageIcon={FaHouseUser}
              title='No Coverage Selected'
              subtitle='No coverage selected by member'
            />
          )}
        </>
      )}
    </DashboardWrapperLayout>
  );
};

export default PlanView;
