import React, { useState } from "react";
import { Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Toggle = ({
  selected,
  editPath,
  viewPath,
  viewText = "View Details",
  editText = "Edit Details",
  newPaths = [],
}) => {
  const navigate = useNavigate();
  const [_selected, setSelected] = useState(selected);
  return (
    <Flex className='flex flex-wrap gap-2 w-full sm:w-auto'>      
      {editPath && (
        <Button
          colorScheme="gray"
          variant={_selected === "edit" ? "solid" : "outline"}
          onClick={() => {
            setSelected("edit");
            navigate(editPath);
          }}
          className="text-md font-medium py-1 px-4 h-8"
        >
          {editText}
        </Button>
      )}
      {newPaths.length > 0 && (
        newPaths.map((path, index) => (
          <Button
            key={index}
            colorScheme="gray"
            variant={_selected === path.text ? "solid" : "outline"}
            onClick={() => {
              setSelected(path.text);
              navigate(path.path);
            }}
            className="text-md font-medium py-1 px-4 h-8"
          >
            {path.text}
          </Button>
        ))
      )}
      {viewPath && (
        <Button
          colorScheme="gray"
          variant={_selected === "view" ? "solid" : "outline"}
          onClick={() => {
            setSelected("view");
            navigate(viewPath);
          }}
          className="text-md font-medium py-1 px-4 h-8"
        >
          {viewText}
        </Button>
      )}      
    </Flex>
  );
};

export default Toggle;
