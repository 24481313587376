import React from 'react';
import { invoiceStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'Inactive' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case invoiceStatusEnum.DRAFT:
      colorScheme = 'gray';
      break;
    case invoiceStatusEnum.DELETED:
      colorScheme = 'yellow';
      break;
    case invoiceStatusEnum.OPEN:
      colorScheme = 'blue';
      break;
    case invoiceStatusEnum.PAID:
      colorScheme = 'green';
      break;
    case invoiceStatusEnum.UNCOLLECTIBLE:
      colorScheme = 'green';
      break;
    case invoiceStatusEnum.VOID:
      colorScheme = 'green';
      break;
    default:
      colorScheme = 'gray';
  }

  return <StatusLabelView status={status} colorScheme={colorScheme} />;
};

export { StatusLabel };
